<div class="modal" [ngClass]="{'show': modalShow}">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title" style="font-size: 17px;" id="staticBackdropLabel">🎉Parabéns🎉</h1>
        </div>
        <div class="modal-body">
            <div *ngIf="this.productsPromotion" style="width: 100%;">
              <span class="span-modal-promotion">Ao pagar no pix você ganhou:</span>
              <span class="span-modal-promotion" style="font-weight: bold; color: black;">{{ this.productsPromotion.additional[0].nameSub }}</span>
              <span class="span-modal-promotion" style="margin-bottom: 10px;">Em qual produto você deseja adicionar?</span>
              <!-- <div style="width: 100%; border-top: 1px solid #c7c7c7; margin-top: 2px;"></div> -->

              <ng-container >
                <div class="order-content-modal">
                    <ng-container *ngFor="let item of this.productsOrder; let i = index">
                        <!-- <div class="order-content__info" [ngClass]="{'selected': this.checked[i] == true}" (click)="this.selected({checked: this.checked[i], value: item}, i)" *ngIf="item.pizza == 1" [ngClass]="{'desableAll': this.desableAll && this.productOrderSelected != item}"> -->
                        <div class="order-content__info" [ngClass]="{'selected': this.checked[i] == true}" (click)="this.selected({checked: this.checked[i], value: item}, i)" *ngIf="item.pizza == 1">
                            <div class="picture-prod">
                              <img [src]="item.imagem_prod"/>
                            </div>
                            <div class="order-content__info__details">
                              <span style="font-size: 14px; font-weight: 100;">{{item.name}}</span>
                              <div class="order-item__additional" >
                                <div class="order-item__category" *ngFor="let categoryAdd of item.categories">
                                  <!-- <span>{{ categoryAdd.categoryName }}</span> -->
                                  <div class="order-item__additional__item" *ngFor="let adicional of categoryAdd.additional">
                                    <span style="font-weight: 100; font-size: 13px; padding-left: 10px; color: #5d5d5d; padding-right: 5px;">{{ adicional.nameSub }}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="div-chekcbox-promo">
                              <!-- <checkbox-radius [checkboxData]="{ checked: false, value: item}" (checkboxChange)="this.selected($event)"></checkbox-radius> -->
                              <checkbox-radius [checkboxData]="{ checked: this.checked[i], value: item}"></checkbox-radius>
                            </div>
                          </div>
                    </ng-container>
                </div>
              </ng-container>

              <span class="span-modal-promotion" style="color: #ad0202; margin-top: 10px; font-size: 13px;">*A promoção só é valida para apenas um item.*</span>
          </div>
        </div>
        <div class="modal-footer justify-content-center">
          <button type="button" class="btn modal-btn-negative" (click)="this.modalShow = false;">Não quero</button>
          <button type="button" class="btn modal-btn-positive" (click)="aceept()" [ngClass]="{'disable': !this.productOrderSelected}">Quero</button>
        </div>
      </div>
    </div>
  </div>