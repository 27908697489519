import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'checkbox-radius',
  templateUrl: './checkbox-radius.component.html',
  styleUrl: './checkbox-radius.component.css'
})
export class CheckboxRadiusComponent implements OnChanges{
  @Input() checkboxData: { checked: boolean, value: number | string | any } = { checked: false, value: 0 };
  @Input() uncheck = false
  //@Output() checkboxChange: EventEmitter<{ checked: boolean, value: number | string }> = new EventEmitter<{ checked: boolean, value: | string }>();
  @Output() checkboxChange: EventEmitter<{ checked: boolean, value: number | string | any }> = new EventEmitter<{ checked: boolean, value: string | number | any }>();


  onCheckboxChange() {
      this.checkboxChange.emit({ checked: this.checkboxData.checked, value: this.checkboxData.value});
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['uncheck'] && !changes['uncheck'].isFirstChange()) {
      if(this.checkboxData.checked){
        this.checkboxData.checked = false;
        console.log('Virou para false denovo')
      }

    }
  }
}
