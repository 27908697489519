import { DatePipe } from '@angular/common';
import { Component, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { id } from 'date-fns/locale';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { typeInterface } from 'src/app/Interfaces/company';
import { Address } from 'src/app/Interfaces/customer';
import { ArrayItem, Order } from 'src/app/Interfaces/order';
import { AuthLayoutComponent } from 'src/app/shared/components/layout/auth-layout/auth-layout.component';
import { ApiService } from 'src/app/shared/services/API/api.service';
import { CacheService } from 'src/app/shared/services/Cache/cache.service';
import { ConnectionService } from 'src/app/shared/services/Connection/connection.service';
import { WebhookService } from 'src/app/shared/services/WeebHook/webhook.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css']
})
export class OrderDetailsComponent implements OnInit {

  //#region Variaveis Locais
    private webHookSubscription!: Subscription;
    private typeParam!: typeInterface;
    private nameCompany: string = '';
    numberQrCode: string = '';
    id_order: number = 0;
    id_empresa!: number;
    order!: Order;
    addressDelivery!: Address;
    items!: ArrayItem;
    numberOrder: string = '';
    dateOrder!: string | null;
    showTopMenu = true;
    flavors: number[] = []
    intervalId: any;
    totalOrderProducts: number = 0;
    discount = 0
  //#endregion

  //#region Inicializadores
    constructor(
      private router: Router,
      private apiService: ApiService,
      private route: ActivatedRoute,
      private datePipe: DatePipe,
      private authLayout: AuthLayoutComponent,
      private connection: ConnectionService,
      private renderer: Renderer2,
      private elementRef: ElementRef,
      private webHookService: WebhookService,
      private toastr: ToastrService,
      )
      {
        this.connection.nameCompany$.subscribe((newNameCompany) => {
          this.nameCompany = newNameCompany
        })
        this.connection.company$.subscribe((newCompany) =>{
          this.id_empresa = newCompany.company.id_company
        })

        //Resgata o nome da empresa e o parametro se é mesa
        this.connection.typeParam$.subscribe((newTypeParam) => {
          this.typeParam = newTypeParam
        })

        this.route.params.subscribe(params => {
          this.id_order = params['id_order'];
          this.getSingleOrder(this.id_order);
      })

      this.authLayout.checkScreenSize();
      this.checkScreenSize();

      }

    ngOnInit(): void {
      this.disablePullToRefresh()

      this.webHookSubscription = this.webHookService.getUpdates().subscribe(
        (content: any) => {
          if(content.type == 1)
          {
            const data = content.data
            if(this.order.id_order == data.company.id_order){
              this.order.orderStatus = data.company.orderStatus
              this.order.events = data.webhooks
              this.order.events
            }
          }
          else if(content.type == 5){
            const data = content.data
              if(data.id_order == this.order.id_order)
              {
                if(data.status == 1){
                  clearInterval(this.intervalId)
                  this.toastr.success('Pagamento Confirmado!', 'Success!', { positionClass: 'toast-top-right', timeOut: 4000});
                  this.order.orderStatus = 1;
                }
                else if(data.status == 6){
                  clearInterval(this.intervalId)
                  this.toastr.warning('Tempo de pagamento expirado!', 'Pix expirado!', { positionClass: 'toast-top-right', timeOut: 4000});
                  this.order.orderStatus = 10;
                }
              }

  
          }
        },
        (error) => {
          console.error('Erro ao receber mensagem do servidor WebHook:', error);
        }
      );
    }

    ngOnDestroy(): void {
      if(this.webHookSubscription) {
        this.webHookSubscription.unsubscribe();
      }
      if(this.intervalId != undefined)
      {
        console.log('Intervalo finalizado', this.intervalId)
      }
    }

  //#endregion

  //#region Funções de Estilo (Style)

    //Função responsavel por travar de o scroll para que a tela não seja recarregada no menu
    disablePullToRefresh() {
      const element = this.elementRef.nativeElement;
      let isAtTop = true;

      // Adiciona um ouvinte para o evento de scroll
      this.renderer.listen(window, 'scroll', () => {
        // Verifica se a página está no topo
        isAtTop = window.scrollY === 0;
      });

      this.renderer.listen(element, 'touchstart', (event) => {
        // Registra a posição inicial do toque
        const startY = event.touches[0].clientY;

        // Adiciona um ouvinte para o evento de movimento de toque
        const touchMoveListener = this.renderer.listen(element, 'touchmove', (moveEvent) => {
          // Calcula a diferença entre a posição inicial e a posição atual
          const deltaY = moveEvent.touches[0].clientY - startY;

          // Se a diferença for positiva (movimento para baixo) e a página estiver no topo, previne o comportamento padrão
          if (deltaY > 0 && isAtTop) {
            moveEvent.preventDefault();
          }
        });

        // Adiciona um ouvinte para o evento de término de toque
        const touchEndListener = this.renderer.listen(element, 'touchend', () => {
          // Remove os ouvintes após o término do toque
          touchMoveListener();
          touchEndListener();
        });
      });
    }

    @HostListener('window:resize', [])
    onWindowResize(event: any) {
      this.checkScreenSize();
    }

    //Verifica o tamanho da tela, esconde o Menu superior se necessario
    checkScreenSize(){
      if(window.innerWidth <= 846){

        this.showTopMenu = false;
      }
      else{
        this.showTopMenu = true;
      }

      this.authLayout.checkScreenSize();
    }

  //#endregion

  //#region Funções de Lógica (Logic)

    goback(): void{
      this.router.navigate([this.nameCompany, 'pedidos']);
    }

    //Faz um request puxando o pedido pelo numero dele
    private getSingleOrder(id_order: number){

      this.apiService.getSingleOrder(id_order).subscribe((data) =>{
        this.order = data.response.info[0].orders[0];
        if(this.order.customer.customerAddress)
        {
          this.addressDelivery = this.order.customer.customerAddress
        }
        this.numberOrder = this.order.displayId;
        this.totalOrderProducts = Number(this.order.orderPrice) - Number(this.order.deliveryFee)

        this.items = this.order;

        const date = new Date(this.order.createdAt)

        this.dateOrder = this.datePipe.transform(date, `dd/MM/yyyy HH:mm`);

        if(this.order.orderStatus == 9)
        {
          this.intervalId = setInterval(() => {
            this.getStatusOrder()
          }, 10000)
        }

        if(this.order.discounts.length != 0)
        {
          this.discount = Number(this.order.discounts[0].discountValue)
        }


      })
    }

    getStatusOrder(){
      this.apiService.getStatusOrder(this.order.id_order.toString()).subscribe(
        (data) =>{
          if(data.status == 1){
            clearInterval(this.intervalId)
            this.toastr.success('Pagamento Confirmado!', 'Success!', { positionClass: 'toast-top-right', timeOut: 4000});
            this.order.orderStatus = 1
          }
          else if(data.status == 10){
            clearInterval(this.intervalId)
            this.order.orderStatus = 10
            this.toastr.warning('Tempo de pagamento expirado!', 'Pix expirado!', { positionClass: 'toast-top-right', timeOut: 4000});
          }
        },
        (error) =>{console.log(error);}
      )
    }
  //#endregion




}
