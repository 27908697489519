import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Address } from 'src/app/Interfaces/customer';

export interface Coordinates {
  accuracy: number;
  altitude: number | null;
  altitudeAccuracy: number | null;
  heading: number | null;
  latitude: number;
  longitude: number;
  speed: number | null;
}

export interface firstResult{
  response: results
}
export interface results{
  results: addressGoogle[]
}
export interface addressGoogle{
  address_components: addressForm[]
}
export interface addressForm{
  long_name: string,
  short_name: string
}

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {

  addressInitial!: Address

  id_AddressSubject: BehaviorSubject<Address | undefined> = new BehaviorSubject<Address | undefined>(undefined);
  id_Address$: Observable<Address | undefined> = this.id_AddressSubject.asObservable();


  constructor(private httpClient: HttpClient) { }

  getLocation(): Promise<Coordinates> {
    return new Promise((resolve, reject) => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => resolve(position.coords),
          (error) => reject(error)
        );
      } else {
        reject('Geolocation is not supported by your browser');
      }
    });
  }

  getLocalizationReal(lat: number, long: number): Observable<results>{

    const apiKey = 'AIzaSyAzkV6hyDKaGYQ9sFq8vJUuUhFnA9uhPtM'

    let url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${apiKey}`
    return this.httpClient.get<results>(url);
  }

  getCep(cep: number):Observable<any>{

    // let url = `https://opencep.com/v1/${cep}`
    let url = `https://viacep.com.br/ws/${cep}/json/`
    return this.httpClient.get<any>(url)
  }



}
