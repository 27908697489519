import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Categorias } from 'src/app/Interfaces/company';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  ex!: Categorias[]

  valueSharedSubject = new BehaviorSubject<Categorias[]>(this.ex);
  valueShared$ = this.valueSharedSubject.asObservable();

  filterSubject = new BehaviorSubject<string>('');
  filter$ = this.filterSubject.asObservable();

  methodReceipt = new BehaviorSubject<boolean>(false);
  methodReceipt$ = this.methodReceipt.asObservable();
  constructor() { }

  
}
