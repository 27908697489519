import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { typeInterface } from 'src/app/Interfaces/company';
import { Cliente } from 'src/app/Interfaces/customer';
import { Fidelity } from 'src/app/Interfaces/fidelity';
import { OrderUser, Pedido } from 'src/app/Interfaces/pedido';
import { ApiService } from 'src/app/shared/services/API/api.service';
import { CacheService } from 'src/app/shared/services/Cache/cache.service';
import { CartService } from 'src/app/shared/services/Cart/cart.service';
import { Company, ConnectionService } from 'src/app/shared/services/Connection/connection.service';

@Component({
  selector: 'app-fidelity',
  templateUrl: './fidelity.component.html',
  styleUrl: './fidelity.component.css'
})
export class FidelityComponent implements OnInit{
  // @Input() fidelityContent = false;
  // @Output() close: EventEmitter<boolean> = new EventEmitter(false);
  private typeParam!: typeInterface;
  nameCompany: string = '';
  user!: Cliente;
  company!: Company;
  fidelityUser!: Fidelity;
  fidelityGet: boolean = false;
  percentageValue: number = 0;
  percentageCount: number = 0;
  userConfirmation: boolean = false;


  // fidelityCheck: boolean = false;
  // modalFidelity: boolean = false;

  // productsFidelity: any[] = []
  // @Input() typeOrder!: number;
  // @Input() order!: OrderUser;
  // @Output() fidelitySelected: EventEmitter<{ check: boolean, fidelity: Fidelity, products: Pedido[], discount: number }> = new EventEmitter<{ check: boolean, fidelity: Fidelity, products: Pedido[], discount: number}>();
  constructor(private router: Router,
    private connection: ConnectionService,
    private cacheService: CacheService,
    private apiService: ApiService,
    private cartService: CartService,
    private toastr: ToastrService,
  ){
    this.connection.typeParam$.subscribe((newTypeParam) => {
      this.typeParam = newTypeParam
    })
  }

  ngOnInit(): void {
    this.connection.fidelity$.subscribe(
      (data) =>{
        if(!data){
          this.getFidelity()
        }
        else{
          this.fidelityUser = data;
          this.fidelityGet = true;
          if(this.fidelityUser.fidelityOption == 1){
            this.calcPercentageCount();
          }
          else{
            this.calcPercentageValue();
          }
        }

    })
    this.connection.nameCompany$.subscribe((newNameCompany) => {
      this.nameCompany = newNameCompany
    })
    this.connection.company$.subscribe((newCompany) =>{
      this.company = newCompany
      if(!this.fidelityUser){
        this.getFidelity();
      }

    })
    this.connection.user$.subscribe((User) =>{
      this.user = User;
      if(!this.fidelityUser){
        this.getFidelity();
      }
    },
    () =>{
      if(this.cacheService.getItemLocal('user_key'))
      {
        this.user = this.cacheService.getItemLocal('user_key')
      }
    })

  }

  goBack(){
    this.router.navigate([this.nameCompany, 'user']);
    // if(!this.fidelityContent){
    //   this.router.navigate([this.nameCompany, 'user']);
    // }
    // else{
    //   this.close.emit(true);
    // }

  }

  //tipo fidelidade 1 é por pedido
  //tipo fidelidade 0 é por valor
  getFidelity(){
    if(this.company){
      if(this.user){
        if(!this.fidelityGet)
        {
          this.apiService.getFidelity(this.user.id_customer, this.company.company.id_company).subscribe(
            (data) =>{
              console.log(data)
              this.fidelityUser = data;
              this.fidelityGet = true;
              if(this.fidelityUser.fidelityOption == 1){
                this.calcPercentageCount();
              }
              else{
                this.calcPercentageValue();
              }
              console.log(this.fidelityUser);
            },
            (error) =>{
              console.log(error);
            }
          )
        }
      }
    }

  }


  calcPercentageCount(){
    this.percentageCount = (this.fidelityUser.ordersCount / this.fidelityUser.fidelityCount) * 100;
  }

  calcPercentageValue(){
    this.percentageValue = (this.fidelityUser.ordersValue / this.fidelityUser.fidelityValue) * 100;
  }

  direct(){
    // if(!this.fidelityContent)
    // {
    //   if(this.fidelityUser.fidelityApproved){
    //     if(this.cartService.cartItems.length > 0){
    //       this.router.navigate([this.nameCompany, 'carrinho']);
    //     }
    //     else{
    //       if(this.typeParam.type == 3){
    //         this.router.navigate([this.nameCompany, 'B']);
    //       }
    //       else{
    //         this.router.navigate([this.nameCompany]);
    //       }
    
    //     }
    //   }
    //   else{
    //     this.toastr.warning(`Complete os requisitos para utilizar seu beneficio`, 'Atenção', { positionClass: 'toast-top-right', timeOut: 4000});
    //   }
    // }
    // else{

    //   if(this.fidelityUser.fidelityApproved){
    //     this.aplication();
    //     this.close.emit(true);
    //   }
    //   else{
    //     this.toastr.warning(`Complete os requisitos para utilizar seu beneficio`, 'Atenção', { positionClass: 'toast-top-right', timeOut: 4000});
    //   }
      

    // }

  }



  // aplication(){
  //   if(this.fidelityUser.fidelityType == 3){
  //     if(this.typeOrder == 5){
  //       this.fidelitySelected.emit({ check: true, fidelity:this.fidelityUser, products: [], discount: 0})
  //       this.fidelityCheck = true;
  //     }
  //     else{
  //       this.toastr.warning('Válido apenas para entrega.', 'Atenção', { positionClass: 'toast-top-right', timeOut: 4000})
  //     }
  //   }
  //   else if(this.fidelityUser.fidelityType == 1){
  //     let count = 0;
  //     for (const product of this.fidelityUser.discountProducts) {
  //       const foundProd = this.order.products.find(prod => prod.id_product === product);      
  //       if (foundProd) {
  //         this.productsFidelity.push(foundProd)
  //         count++
  //       }
  //     }

  //     if(count == 1){
  //       this.fidelityProducts(this.fidelityUser);
  //     }
  //     else{
  //       if(this.fidelityUser.bonusType != 1){
  //         this.modalFidelity = true;
  //       }
  //       else{
  //         let discount = Number(this.fidelityUser.bonusValue);
  //         this.fidelityCheck = true;
  //         this.fidelitySelected.emit({ check: true, fidelity:this.fidelityUser, products: this.order.products, discount: discount})
  //       }
  //     }
  //   }
  //   else{
  //     this.fidelitySelected.emit({ check: true, fidelity:this.fidelityUser, products: [], discount: 0})
  //     this.fidelityCheck = true;
  //   }

  // }

  // fidelityProducts(fidelity: Fidelity){
  //   this.percentDiscont(fidelity);
  // }

  // //Desconto em porcentagem 
  // percentDiscont(fidelity: Fidelity){
  //   let discount = 0
  //   for (const product of fidelity.discountProducts) {
  //     const foundProd = this.order.products.findIndex(prod => prod.id_product === product);
    
  //     if (foundProd !== -1) {
  //       discount = Number(fidelity.bonusPercent) / 100;
  //       let discountFormatted;

  //       //Product
  //       if(this.order.products[foundProd].quantity > 1){
  //         const realValue = this.order.products[foundProd].totalPrice / this.order.products[foundProd].quantity;
  //         discountFormatted = realValue * discount;
  //       }
  //       else{
  //         discountFormatted = this.order.products[foundProd].totalPrice * discount;
  //       }
  //       this.order.products[foundProd].oldPrice = this.order.products[foundProd].totalPrice;
  //       this.order.products[foundProd].totalPrice -= discountFormatted;
  //       this.order.products[foundProd].discountValue = discountFormatted;
  //       this.order.products[foundProd].discount = true;
  //       this.fidelityCheck = true;
  //       this.fidelitySelected.emit({ check: true, fidelity:this.fidelityUser, products: this.order.products, discount: discountFormatted})
  //       break;
  //     }
  //   }



  // }
  
  // //Quando a mais de um produto
  // prodSelected(event: any){
  //   let product = event.product

  //   if(this.fidelityUser.bonusType == 2){

  //     const foundProd = this.order.products.findIndex(prod => prod.id_product === product);
      
  //     if (foundProd !== -1) {
  //       const discount = Number(this.fidelityUser.bonusPercent) / 100;
  //       let discountFormatted;

  //       //Product
  //       if(this.order.products[foundProd].quantity > 1){
  //         const realValue = this.order.products[foundProd].totalPrice / this.order.products[foundProd].quantity;
  //         discountFormatted = realValue * discount;
  //       }
  //       else{
  //         discountFormatted = this.order.products[foundProd].totalPrice * discount;
  //       }
  //       this.order.products[foundProd].oldPrice = this.order.products[foundProd].totalPrice;
  //       this.order.products[foundProd].totalPrice -= discountFormatted;
  //       this.order.products[foundProd].discountValue = discountFormatted;
  //       this.order.products[foundProd].discount = true;
  //       this.fidelitySelected.emit({ check: true, fidelity:this.fidelityUser, products: this.order.products, discount: discountFormatted})
  //     }
  //   }
  //   else if(this.fidelityUser.bonusType == 1){
  //     const foundProd = this.order.products.findIndex(prod => prod.id_product === product);
    
  //     if (foundProd !== -1) {
  //       const discount = Number(this.fidelityUser.bonusValue);
  //       //Product
  //       this.order.products[foundProd].oldPrice = this.order.products[foundProd].totalPrice
  //       this.order.products[foundProd].totalPrice -= discount;
  //       this.order.products[foundProd].discountValue = discount;
  //       this.order.products[foundProd].discount = true;
  //       this.fidelitySelected.emit({ check: true, fidelity:this.fidelityUser, products: this.order.products, discount: discount})
  //     }
  //   }
  //   this.fidelityCheck = true;
  //   this.modalFidelity = false;
  // }

  // remove(){
  //   this.productsFidelity = []
  //   this.modalFidelity = false;
  //   this.fidelityCheck = false;
  //   this.fidelitySelected.emit({ check: false, fidelity:this.fidelityUser, products: [], discount: 0})
  //   this.close.emit(true);
  // }
  
}
