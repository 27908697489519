import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { OrderUser, Pedido} from 'src/app/Interfaces/pedido';
import { AuthLayoutComponent } from 'src/app/shared/components/layout/auth-layout/auth-layout.component';
import { ApiService } from 'src/app/shared/services/API/api.service';
import { CacheService } from 'src/app/shared/services/Cache/cache.service';
import { Company, ConnectionService } from 'src/app/shared/services/Connection/connection.service';
import { UserService } from 'src/app/shared/services/User/user.service';
import { typeInterface } from 'src/app/Interfaces/company';
import { Address, Cliente, Usuario } from 'src/app/Interfaces/customer';

@Component({
  selector: 'app-user-data',
  templateUrl: './user-data.component.html',
  styleUrls: ['./user-data.component.css']
})
export class UserDataComponent implements OnInit{
  @ViewChild('inputPhone') inputPhone!: ElementRef;
  @ViewChild('inputName') inputName!: ElementRef

  //#region Variaveis de Estilo
    isChecked: boolean = false;
    foundUser = false;
    lengthInputName: any;
    lengthInputPhone = false;
    editUser = false;
    corretFormatPhoneNumber: any;
  //#endregion

  //#region Variaveis Locais
    private typeParam!: typeInterface;
    private nameCompany: string = '';

    phoneNumberPattern = /^\(\d{2}\)\d{5}\-\d{4}$/;
    user!: Cliente;
    id_customer: string = '';
    name: string = '';
    phoneNumber: string = '';
    company!: Company
  //#endregion

  //#region Inicializadores

    constructor(
      private router: Router,
      private apiService: ApiService,
      private cacheService: CacheService,
      private connection: ConnectionService,
      private authLayout: AuthLayoutComponent,
      private userService: UserService,
      private location: Location,
      private renderer: Renderer2,
      private elementRef: ElementRef,
      ){
        this.connection.nameCompany$.subscribe((newNameCompany) => {
          this.nameCompany = newNameCompany;
        })

        this.userService.editingUser$.subscribe((newvalue) =>{
          this.foundUser = newvalue
        })

        this.connection.typeParam$.subscribe((newType) => {
          this.typeParam = newType
        })

        this.connection.company$.subscribe((newCompany) =>{
          this.company = newCompany
        })

        this.connection.user$.subscribe(
          (data) =>{
            if(data){
              this.user = data
              this.phoneNumber = this.user.phone
              this.name = this.user.name
            }
            // else if(this.cacheService.getItemLocal('user_key')){
            //   this.connection.getUser();
            // }
  
          }
        )
    }

    ngOnInit(): void {
      this.disablePullToRefresh()

      this.authLayout.checkScreenSize();

      this.validator();

    }

  //#endregion

  //#region Funções de Estilo (Style)

    //Função responsavel por travar de o scroll para que a tela não seja recarregada no menu
    disablePullToRefresh() {
      const element = this.elementRef.nativeElement;
      let isAtTop = true;

      // Adiciona um ouvinte para o evento de scroll
      this.renderer.listen(window, 'scroll', () => {
        // Verifica se a página está no topo
        isAtTop = window.scrollY === 0;
      });

      this.renderer.listen(element, 'touchstart', (event) => {
        // Registra a posição inicial do toque
        const startY = event.touches[0].clientY;

        // Adiciona um ouvinte para o evento de movimento de toque
        const touchMoveListener = this.renderer.listen(element, 'touchmove', (moveEvent) => {
          // Calcula a diferença entre a posição inicial e a posição atual
          const deltaY = moveEvent.touches[0].clientY - startY;

          // Se a diferença for positiva (movimento para baixo) e a página estiver no topo, previne o comportamento padrão
          if (deltaY > 0 && isAtTop) {
            moveEvent.preventDefault();
          }
        });

        // Adiciona um ouvinte para o evento de término de toque
        const touchEndListener = this.renderer.listen(element, 'touchend', () => {
          // Remove os ouvintes após o término do toque
          touchMoveListener();
          touchEndListener();
        });
      });
    }

    validator(){
      if(this.phoneNumber.length === 14){
        this.foundUser = true;
        this.corretFormatPhoneNumber = true;
        this.lengthInputPhone = true;
      }
      if(this.name.length >= 3){
        this.lengthInputName = true;
      }
    }

  //#endregion

  //#region Funções de Lógica (Logic)

    //retorna a pagina anterior
    goback(): void{
      this.location.back()
    }

    //Valida e formata o telefone do usuario
    validatorPhoneNumber(phone: string){
      let rawValue = ''
      let formatted = '';

      rawValue = phone.replace(/\D/g, '');

      if(rawValue != '')
      {
        const firstPart = rawValue.substring(0, 2)
        const secondPart = rawValue.substring(2, 7)
        const treetPart = rawValue.substring(7, 11)

        if(rawValue.length >= 1 && rawValue.length < 2){
          formatted = `(${firstPart}`
        }
        else if(rawValue.length >= 2 && rawValue.length < 7){
          formatted = `(${firstPart})`
        }
        else if(rawValue.length >= 7)
        {
          formatted = `(${firstPart})${secondPart}-${treetPart}`
        }

      }
      else{
        formatted = phone;
      }

      this.phoneNumber = formatted;
      return true;

    }

    //Assim que confirmado ele monta os objetos necessarios com os valores dos campos
    submitForm(){

      if(this.lengthInputName && this.lengthInputPhone){
        let user = this.cacheService.getItemLocal('user_key');
        let userAddress = this.cacheService.getItemLocal('userAddress');
        if(user)
        {
          if(user.name != this.name || user.phone != this.phoneNumber)
          {
            this.editUser = true;
            if(this.id_customer != user.id_customer){
              this.id_customer = user.id_customer
            }
  
          }
        }
  
        const cliente: Cliente = {
          name: this.name,
          phone: this.phoneNumber,
          id_customer: this.id_customer,
          document: ''
        }
  
        if(this.foundUser == false)
        {
          if(this.validatorPhoneNumber(this.phoneNumber))
          {
            cliente.phone = this.phoneNumber;
  
            this.apiService.postUsuario(cliente).subscribe(
              (data) => {
                console.log('Usuario cadastrado com sucesso:', data);
    
                const userKey = {
                  phone: this.phoneNumber,
                  id_customer: this.id_customer,
                  name: this.name
                }
  
                if(user){
  
                  if(data.response.Customer.id_customer != user.id_customer){
                    this.cacheService.removeItemLocal('userAddress')
                    this.cacheService.setItemLocal('user_key', userKey)
                  }
                }
    
                this.connection.updateUser(data.response.Customer)
                this.cacheService.setItemLocal('user_key', userKey)
    
                if(userAddress)
                {
                  this.cacheService.removeItemLocal('userAddress')
                }
    
                if(this.typeParam.type == 3)
                {
                  this.router.navigate([this.nameCompany, 'order-confirmation-counter']);
                }
                else{
                  this.router.navigate([this.nameCompany, 'order-confirmation']);
                }
    
    
              },
              (error) => {
                console.error('Erro ao cadastrar usuario:', error);
                this.apiService.getUsuario(this.phoneNumber).subscribe(
                  (data) => {
                    this.handleSuccessfulResponse(data)
                  },
                  (error) => {
                    this.handleError(error)
                  }
                )
              }
            );
          }
  
        }
        else{
          if(this.validatorPhoneNumber(this.phoneNumber))
          {
            cliente.phone = this.phoneNumber;
            this.pathUser();
          }
  
        }
      }


    }

    //Edita o usuario
    pathUser(){
      if(this.editUser){
        this.apiService.patchUser(this.id_customer, this.name, this.phoneNumber).subscribe(
          (data) =>{

            const userKey = {
              phone: data.response.phone,
              id_customer: data.response.id_customer,
              name: data.response.name
            }

            this.cacheService.setItemLocal('user_key', userKey)
            this.connection.updateUser(data.response)

            if(this.typeParam.type == 3)
            {
              this.router.navigate([this.nameCompany, 'order-confirmation-counter']);
            }
            else{
              this.router.navigate([this.nameCompany, 'order-confirmation']);
            }
          },
          (error) =>{
            console.log('Erro ao editar usuario:')
          })
      }
      else{
        if(this.typeParam.type == 3)
        {
          this.router.navigate([this.nameCompany, 'order-confirmation-counter']);
        }
        else{
          this.router.navigate([this.nameCompany, 'order-confirmation']);
        }
      }
    }

    //Acionado toda vez que uma letra é digitada no input do nome
    onInputChangeName(event:any)
    {
      const length = event.target.value.length
      if(length >= 3 )
      {
        this.lengthInputName = true;
      }
      else{
        this.lengthInputName = false;
      }

    }

    //Chamada toda vez que é digitado um numero, se o numero telefone for maior igual a 14 ele faz os requests
    onInputChange(event: any){
      const newValue = event.target.value;

      if(newValue.length > 13)
      {
        this.lengthInputPhone = true;
      }
      else{
        this.lengthInputPhone = false;
      }

      let rawValue = ''
      let formatted = ''

      rawValue = newValue.replace(/\D/g, '');

      if(rawValue != '')
      {
        const firstPart = rawValue.substring(0, 2)
        const secondPart = rawValue.substring(2, 7)
        const treetPart = rawValue.substring(7, 11)


        if(rawValue.length >= 1 && rawValue.length < 2){
          formatted = `(${firstPart}`
        }
        else if(rawValue.length >= 2 && rawValue.length < 7){
          formatted = `(${firstPart})${secondPart}`
        }
        else if(rawValue.length >= 7)
        {
          formatted = `(${firstPart})${secondPart}-${treetPart}`
        }

      }
      else{
        this.name = '';
        this.lengthInputName = undefined;
        formatted = rawValue
      }

      this.phoneNumber = formatted;

      if(rawValue != '')
      {
        this.corretFormatPhoneNumber = this.phoneNumberPattern.test(this.phoneNumber)
      }
      else{
        this.corretFormatPhoneNumber = undefined
      }

      if(this.phoneNumber.length === 14){
        this.apiService.getUsuario(this.phoneNumber).subscribe(
          (data) => {
            this.handleSuccessfulResponse(data)
          },
          (error) => {
            console.log('Usuario não cadastrado');
            this.handleError(error)
          }
        )
      }
    }

    //Função para lidar com a resposta bem-sucedida
    private handleSuccessfulResponse(data: any): void {
      this.name = data.response.customer.name;
      this.id_customer = data.response.customer.id_customer;

      this.foundUser = true;
      this.lengthInputName = true;
      this.lengthInputPhone = true;

      const user = this.cacheService.getItemLocal('user_key');

      const userKey = {
        phone: data.response.customer.phone,
        id_customer: data.response.customer.id_customer,
        name: data.response.customer.name
      }

      if (user) {
        if (this.id_customer !== user.id_customer) {
          this.cacheService.removeItemLocal('userAddress');
          this.cacheService.setItemLocal('user_key', userKey);
        }
      } else {
        this.cacheService.setItemLocal('user_key', userKey);
      }

      this.connection.updateUser(data.response.customer);

      this.inputPhone.nativeElement.blur();
    }

    //Função para lidar com erros
    private handleError(error: any): void {

      this.name = '';
      this.foundUser = false;
      if(this.name.length >= 3)
      {
        this.lengthInputName = true;
      }
      else{
        this.lengthInputName = false;
      }

      this.inputPhone.nativeElement.blur();
      this.inputName.nativeElement.focus();
    }

  //#endregion

}

