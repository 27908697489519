
<div class="top-menu" id="top-menu">
  <div class="menu-container">
      <!-- <div class="menu-search row-center" *ngIf="!alterationMenu">
          <div class="search-bar row-center">
              <div class="search-bar-input" >
                <div class="div-input" #divInput>
                  <div class="icon-search" #divIcon>
                      <i class="fa fa-search" aria-hidden="true" style="padding-right: 10px;" type="button"></i>
                  </div>
                  <input class="desktop" type="text" placeholder="Esta com fome? Pedeaqu!" #inputDesktop>
                </div>
              </div>
          </div>
      </div> -->
      <div class="menu-company">
        <div class="menu-company__logo"><img [src]="this.img" /></div>
        <div class="menu-company__name">{{ this.company.company.companyName }}</div>
      </div>
      <ng-container *ngIf="this.company">
        <div class="menu-top-buttons" *ngIf="!this.readOnlyState">
          <a class="btn" type="button" [routerLink]="['/', this.nameCompany, 'carrinho']"><i class="fa-solid fa-cart-shopping" aria-hidden="true"></i><span>Carrinho</span><span *ngIf="this.counterCart > 0" class="icon-quantity">{{ counterCart }}</span></a>
          <a [routerLink]="['/', this.nameCompany, 'consumption']" *ngIf="this.verificationTable"><i class="fa-solid fa-book" style="text-align: center;"></i><span>Consumo</span></a>
          <a [routerLink]="['/', this.nameCompany, 'pedidos']" *ngIf="this.userConfirmation && !this.verificationTable"><i class="fa-solid fa-book" style="text-align: center;"></i><span>Pedidos</span></a>
          <a [routerLink]="['/', this.nameCompany, 'user']" *ngIf="!this.verificationTable"><i class="fa-solid fa-user" style="text-align: center;"></i><span>Perfil</span></a>
        </div>
      </ng-container>

  </div>
</div>
<div class="modal fade" id="modalInfoEmpresa" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalToggleLabel">Sobre o estabelecimento</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="description-company">
          <div class="description-first">
            <div class="description-title">
              <div>
                <span>Empresa</span>
              </div>
          </div>
            <div class="description-body">
              <div>
                <span>Rua Coronel Amazonas Marcondes, 1482 - Cabral, Curitiba - PR, 80.035-230, Brasil</span>
                <div class="icon-container">
                  <a href="https://www.google.com.br/maps/place/R.+Cel.+Amazonas+Marcondes,+1482+-+Ah%C3%BA,+Curitiba+-+PR,+80035-230/@-25.3997381,-49.2592566,17z/data=!3m1!4b1!4m6!3m5!1s0x94dce69ba473c6ed:0x2b9b9b5ea2cfb6e1!8m2!3d-25.399743!4d-49.2566817!16s%2Fg%2F11cr_gkkzl?entry=ttu"><i class="fa fa-chevron-right" aria-hidden="true"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>

