<app-top-menu-two></app-top-menu-two>
<div class="container" style="overflow: auto;">
  <div class="navegation-header d-flex align-items-center">
    <div class="box-icon" (click)="goback()">
      <i class="fa fa-chevron-left" aria-hidden="true"></i>
    </div>
    <div class="box-title">
      <h3 style="margin-bottom: 0px;">Identifique-se</h3>
    </div>
  </div>
  <form (ngSubmit)="submitForm()">
    <div style="padding-bottom: 90px;" class="fist-box">
      <div class="user-data">
        <div class="d-flex px-2 flex-column box-data">
          <span>Número de celular:</span>
          <input class="form-control" type="text" placeholder="(_ _) _ _ _ _ _ - _ _ _ _" [(ngModel)]="this.phoneNumber" name="phoneNumber" [ngClass]="{'is-valid': this.corretFormatPhoneNumber == true, 'is-invalid': this.corretFormatPhoneNumber == false}" (input)="onInputChange($event)" maxlength="14" #inputPhone required>
        </div>
        <div class="d-flex px-2 flex-column box-data">
          <span>Nome e sobrenome:</span>
          <input class="form-control" type="text" [(ngModel)]="name" name="name" placeholder="Nome e sobrenome" [ngClass]="{'is-valid': this.lengthInputName == true, 'is-invalid': this.lengthInputName == false}" (input)="onInputChangeName($event)" required="true" #inputName>
        </div>
        <p class="warning">Para realizar seu pedido vamos precisar de suas informações</p>
      </div>
    </div>
    <div class="bottom-navegation">
      <div class="bottom-navegation__box">
        <button type="submit" class="buttonConfirmation" [ngClass]="{'not-disabled': lengthInputName && lengthInputPhone}">
          Confirmar pedido
        </button>
      </div>
    </div>

    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" data-bs-dismiss="modal">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="staticBackdropLabel">Confirme seus dados</h1>
          </div>
          <div class="modal-body">
            <div class="idendity">
              <div class="idendity-box-name">
                <div class="icon-content">
                  <i class="fa-solid fa-user" style="color:#652969"></i>
                </div>
                <div class="content">
                  <p>Nome</p>
                  <h4>{{ this.name }}</h4>
                </div>
              </div>
              <div class="idendity-box-phone">
                <div class="icon-content">
                  <i class="fa-solid fa-phone" style="color:#652969"></i>
                </div>
                <div class="content">
                  <p>Telefone</p>
                  <h4>{{ this.phoneNumber }}</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-center" style="flex-direction: column;">
            <button type="button" class="btn btn-edit" data-bs-dismiss="modal">Editar</button>
            <button type="submit" class="btn btn-confirm">Confirmar</button>
          </div>
        </div>
      </div>
    </div>
</form>
</div>


<!-- Modal -->


