import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Address, Cliente } from 'src/app/Interfaces/customer';
import { AuthLayoutComponent } from 'src/app/shared/components/layout/auth-layout/auth-layout.component';
import { ApiService } from 'src/app/shared/services/API/api.service';
import { CacheService } from 'src/app/shared/services/Cache/cache.service';
import { ConnectionService } from 'src/app/shared/services/Connection/connection.service';
import { GeolocationService, Coordinates, addressGoogle} from 'src/app/shared/services/GeoLocation/geolocation.service';
import { EventsService } from 'src/app/shared/services/events/events.service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css', "../../../assets/styles/global/_modal.scss"]
})
export class AddressComponent implements OnInit{

  private idAddress = '';
  private AddressEditing!: Address

  optionHome = {checked: false, value: 'Casa'}
  optionWork = {checked: false, value: 'Trabalho'}
  optionSchool = {checked: false, value: 'Escola'}


  showTopMenu = true;
  openModalAskTitle = false;
  showAddress = false;
  showLoading = false;
  showInput = false;
  cep: string = '';
  coordinates!: Coordinates
  addressGoogle!: addressGoogle
  user!: Cliente;
  nameCompany: string = '';
  requestApi = false;
  selectedAddressIdenfier!: string;
  address: Address = {
    country: '',
    state: '',
    city: '',
    neighborhood: '',
    street: '',
    postalCode: '',
    number: '',
    complement: '',
    reference: '',
    identifier: '',
    id_address: '',
    deliveryPrice: 0,
    deliverydistance: 0
  };

  constructor(
    private router: Router,
    private connection: ConnectionService,
    private apiService: ApiService,
    private geolocation: GeolocationService,
    private authLayout: AuthLayoutComponent,
    private eventsService: EventsService,
    private cacheService: CacheService,
    private changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private toastr: ToastrService,
    private elementRef: ElementRef,
    @Inject(DOCUMENT) private document: Document,
    ){
    this.connection.nameCompany$.subscribe((newNameCompany) => {
      this.nameCompany = newNameCompany;
    })
    this.geolocation.id_Address$.subscribe((newAddress) =>{
      if(newAddress){
        this.AddressEditing = newAddress
      }
    })
    this.checkScreenSize();
  }

  ngOnInit(): void {
      this.disablePullToRefresh()
      this.connection.user$.subscribe((User) =>{
        this.user = User;
      },
      (error) =>{
        if(this.cacheService.getItemLocal('user_key'))
        {
          this.user = this.cacheService.getItemLocal('user_key')
        }
      }
      )
      this.eventsService.eventNavegationSubject.next(true);

      if(this.AddressEditing){
        this.address = this.AddressEditing
        this.requestApi = true
      }
  }

  disableScroll(){
    this.renderer.setStyle(this.document.body, 'overflow', 'hidden')
  }

  //Verifica o tamanho da tela, esconde o Menu superior se necessario
  private checkScreenSize(){
    if(window.innerWidth <= 846){

      this.showTopMenu = false;
    }
    else{
      this.showTopMenu = true;
    }
    this.authLayout.checkScreenSize();
  }

  @HostListener('window:resize', [])
  onWindowResize(event: any) {
    this.checkScreenSize();
  }

  //Abilita o scroll da pagina
  enableScroll(){
    this.renderer.removeStyle(this.document.body, 'overflow');
  }

  //Função responsavel por travar de o scroll para que a tela não seja recarregada no menu
  disablePullToRefresh() {
    const element = this.elementRef.nativeElement;
    let isAtTop = true;

    // Adiciona um ouvinte para o evento de scroll
    this.renderer.listen(window, 'scroll', () => {
      // Verifica se a página está no topo
      isAtTop = window.scrollY === 0;
    });

    this.renderer.listen(element, 'touchstart', (event) => {
      // Registra a posição inicial do toque
      const startY = event.touches[0].clientY;

      // Adiciona um ouvinte para o evento de movimento de toque
      const touchMoveListener = this.renderer.listen(element, 'touchmove', (moveEvent) => {
        // Calcula a diferença entre a posição inicial e a posição atual
        const deltaY = moveEvent.touches[0].clientY - startY;

        // Se a diferença for positiva (movimento para baixo) e a página estiver no topo, previne o comportamento padrão
        if (deltaY > 0 && isAtTop) {
          moveEvent.preventDefault();
        }
      });

      // Adiciona um ouvinte para o evento de término de toque
      const touchEndListener = this.renderer.listen(element, 'touchend', () => {
        // Remove os ouvintes após o término do toque
        touchMoveListener();
        touchEndListener();
      });
    });
  }

  goback(): void{
    this.geolocation.id_AddressSubject.next(undefined)
    this.router.navigate([this.nameCompany, 'order-confirmation']);
  }

  selectIdentifier(nameIdentifier: string){
    this.selectedAddressIdenfier = nameIdentifier;
    this.address.identifier = nameIdentifier;
    this.changeDetectorRef.detectChanges();
  }

  getLocalization(){
    this.geolocation.getLocation().then((coords)=>{
      this.coordinates = coords;
      this.showLoading = true;
      this.geolocation.getLocalizationReal(this.coordinates.latitude, this.coordinates.longitude).subscribe(
        (response) =>{
          if(response)
          {
            this.addressGoogle ={
              address_components: response.results[0].address_components
            }
            const address: Address = {
              country: this.addressGoogle.address_components[5].long_name,
              state: this.addressGoogle.address_components[4].short_name,
              city: this.addressGoogle.address_components[3].long_name,
              neighborhood: this.addressGoogle.address_components[2].long_name,
              street: this.addressGoogle.address_components[1].long_name,
              postalCode: this.addressGoogle.address_components[6].long_name,
              number: this.addressGoogle.address_components[0].long_name,
              complement: '',
              reference: '',
              identifier: '',
              id_address: '',
              deliveryPrice: 0,
              deliverydistance: 0
            }
            this.address = address
            this.requestApi = true
            this.showLoading = false;
          }
        },
        (error) =>{
          console.error('Erro ao tentar fazer o request', error)
          this.toastr.warning('Não conseguimos encontrar sua localização!', 'Localização não encontrada!', { positionClass: 'toast-top-right', timeOut: 5000,});
          this.showLoading = false;
        })
    },
    (error) =>{
      this.toastr.warning('Não conseguimos encontrar sua localização!', 'Localização não encontrada!', { positionClass: 'toast-top-right', timeOut: 5000,});
      console.log(error)
      this.showLoading = false;
    }).catch((error) => console.error('Error getting location:', error));


  }

  onInputChange(event: any){
    const value = event.target.value
    const length = event.target.value.length

    if(length == 8){
      this.showAddress = false
      this.showLoading = true;
      this.geolocation.getCep(Number(value)).subscribe(
        (data) =>{
          if(data){

            let street = data.logradouro.split('-');
            let streetFormatted  = street[0].trim();
            let cepFormatted = data.cep.replace(/\D/g, '')

            const address: Address = {
              country: 'Brasil',
              state: data.uf,
              city: data.localidade,
              neighborhood: data.bairro,
              street: streetFormatted,
              postalCode: cepFormatted,
              number: '',
              complement: '',
              reference: '',
              identifier: '',
              id_address: '',
              deliveryPrice: 0,
              deliverydistance: 0
            }
            // const address: Address = {
            //   country: 'Brasil',
            //   state: data.state,
            //   city: data.city,
            //   neighborhood: data.neighborhood,
            //   street: streetFormatted,
            //   postalCode: data.cep,
            //   number: '',
            //   complement: '',
            //   reference: '',
            //   identifier: '',
            //   id_address: '',
            //   deliveryPrice: 0,
            //   deliverydistance: 0
            // }
            this.address = address

            setTimeout(() => {
              this.showLoading = false;
              this.showAddress = true;
            }, 1000);

          }

        },
        (error) =>{
          console.error('Erro ao tentar fazer o request', error)
          this.requestApi = true;
          this.showLoading = false;
        })
    }
  }

  openModalAddress(){
    this.openModalAskTitle = true;
    this.disableScroll()
  }

  addAddress(){

    if(this.AddressEditing != undefined){
      this.address.deliveryPrice = 0
      delete this.address.formattedaddress
      // console.log(this.address)
      this.apiService.pathAddress(this.user.id_customer, this.address).subscribe((data) =>{
        // console.log(data)
        this.geolocation.id_AddressSubject.next(undefined)
        this.connection.updateUser(data.response)
        this.cacheService.setItemLocal('user_key', data.response)
        this.toastr.success('Endereço editado com sucesso!', 'Success!', { positionClass: 'toast-top-right', timeOut: 1500});
        this.router.navigate([ this.nameCompany, 'order-confirmation'])
      },
      (error) =>{
        // console.log(error)
        this.toastr.error('Falha ao editar o endereço!', 'Erro!', { positionClass: 'toast-top-right', timeOut: 1500});
      });
    }
    else{
      // console.log(this.address)
      this.apiService.postAddress(this.user.id_customer, this.address).subscribe((response) =>{
        // console.log(response)
        this.connection.updateUser(response.response)
        this.cacheService.setItemLocal('user_key', response.response)
        this.cacheService.removeItemLocal('userAddress')
        this.openModalAskTitle = false;
        this.toastr.success('Endereço cadastrado com sucesso!', 'Success!', { positionClass: 'toast-top-right', timeOut: 1500});
        this.router.navigate([ this.nameCompany, 'order-confirmation'])
      },
      (error) =>{
        // console.log(error)
        this.toastr.error('Falha ao cadastrar o endereço!', 'Erro!', { positionClass: 'toast-top-right', timeOut: 1500});
      });
    }

    this.enableScroll()

  }

  onCheckboxChange(data: { checked: boolean, value: any }) {
    // this.selectedOption = { checked: data.checked, value: data.value};
    if(data.value == 'Casa'){
      this.optionSchool.checked = false;
      this.optionWork.checked = false;
    }
    else if(data.value == 'Trabalho')
    {
      this.optionHome.checked = false;
      this.optionSchool.checked = false;
    }
    else if(data.value == 'Escola')
    {
      this.optionHome.checked = false;
      this.optionWork.checked = false;
    }
    this.selectIdentifier(data.value)
  }



}
