import { ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CacheService } from '../../shared/services/Cache/cache.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {OrderService} from 'src/app/shared/services/Order/order.service';
import { Pedido} from 'src/app/Interfaces/pedido';
import { ApiService } from 'src/app/shared/services/API/api.service';
import { AuthLayoutComponent } from 'src/app/shared/components/layout/auth-layout/auth-layout.component';
import { Company, ConnectionService } from 'src/app/shared/services/Connection/connection.service';
import { CartService } from 'src/app/shared/services/Cart/cart.service';
import { event } from 'src/app/shared/services/events/events.service';
import { BottomMenuComponent } from 'src/app/shared/components/bottom-menu/bottom-menu.component';
import { DOCUMENT } from '@angular/common';
import { buttonVibration } from 'src/app/shared/animations/button-vibration.animation';
import { WebhookService } from 'src/app/shared/services/WeebHook/webhook.service';
import { Subscription } from 'rxjs';
import { FormPaymentLocal, Order, paymentForm } from 'src/app/Interfaces/order';
import { typeInterface } from 'src/app/Interfaces/company';


@Component({
  selector: 'app-consumption',
  templateUrl: './consumption.component.html',
  styleUrls: ['./consumption.component.css', "../../../assets/styles/global/_modal.scss"],
  animations: [buttonVibration],
})
export class ConsumptionComponent implements OnInit {

  //#region Variaveis de elementos HTML
  @ViewChild('inputAddObs') inputAddObs!: ElementRef;
  @ViewChild('inputEditObs') inputEditObs!: ElementRef;
  @ViewChild('inputAlterationTable') inputAlterarionTable!: ElementRef;
  @ViewChild('callWatersTable') callWatersTable!: ElementRef
  @ViewChild('callWaitersCardInput') callWaitersCardInput!: ElementRef
  @ViewChild('callWaitersCardObs') callWaitersCardObs!: ElementRef
  //#endregion

  //#region Variaveis de estilo
    private webHookSubscription!: Subscription;
    showTopMenu = true;
    alterationString = false;
    alterationButtonModalCall = false;
    openModalCallTable = false;
    openModalCallCard = false;
    openModalPayment = false;
    isCollapsedTroco: boolean = false;
    openDivSplit: boolean = false;
    openModalEditObs = false;
    openModaladdObs = false;
    openModalAlterationTable = false;
    openModalPermissionRequest = false;
    clicked = false;
    desabilitButton = true;
    permissionRequest = true;
    requestSucess: boolean = true;
  //#endregion

  //#region Variaveis Locais

    //Variaveis Privadas
    public typeParam!: typeInterface;
    private nameCompany: string = '';
    private clearOrder!: Order;

    //Variaveis String
    vibrationState = 'inactive';
    countText: number = 0;
    verification!: string;
    tableNumberSelected: string = '';
    textObservationModal: string = '';
    nameIdentification: string = '';
    numberRealized: string = '';
    valorInputModal: string = '';
    textObservationWaiter: string = '';
    numberTable: string = '';

    //Variaveis Numericas
    id_empresa!: number;
    numberCard: number = 0;
    totalDivision = 0;
    counter = 1;
    totalChange = 0;
    id_Order = 0;
    total = 0;
    temporaryTotal = 0;
    indexCLicked!: number;

    //Variaveis de lista
    selectedPaymentForms: paymentForm[] = [];

    //Variaveis de Objetos
    company!: Company
    order!: Order;

  //#endregion

  //#region Inicializadores
    constructor(
      private cacheService: CacheService,
      private router: Router,
      private apiService: ApiService,
      private connection: ConnectionService,
      private authLayout: AuthLayoutComponent,
      private ChangeDetectorRef: ChangeDetectorRef,
      private toastr: ToastrService,
      private orderService: OrderService,
      private cartService: CartService,
      private bottomMenu: BottomMenuComponent,
      private renderer: Renderer2,
      private webHookService: WebhookService,
      private elementRef: ElementRef,
      @Inject(DOCUMENT) private document: Document,
      ){
        this.authLayout.checkScreenSize();
        this.checkScreenSize();
    }

    //Chamado assim que inicia o component
    ngOnInit(): void {
      this.webHookSubscription = this.webHookService.getUpdates().subscribe(
        (content: any) => {

          if(content.type == 3 || content.type == 4)
          {
            const data = content.data
            if(data.info){
              if(data.info[0].orders[0].id_order == this.order.id_order)
              {
                console.log('Pedido recebido do servidor WebHook:', data);
                this.order = data.info[0].orders[0];
                this.total = this.calcTotalOrder();
                this.totalDivision = this.total;
              }
            }
            else if(data.webhooks){
              const counter = data.webhooks.length - 1

              this.tableNumberSelected = data.webhooks[counter].tableNumber.toString()
              this.numberTable = data.webhooks[counter].tableNumber.toString()
            }
          }
          else if(content.type == 1){
            const data = content.data

            if(data.webhooks){
              if(this.order.id_order == data.webhooks[0].id_order){
                if(data.webhooks[0].orderStatus == 0 || data.webhooks[0].orderStatus == 5){
                  this.router.navigate(['/Authentication']);
                }
                else if(data.webhooks[0].orderStatus == 20){
                  this.connection.permissionRequestOrderSubject.next(false);
                  this.permissionRequest = false;
                }
                else if(data.webhooks[0].orderStatus == 1){
                  this.connection.permissionRequestOrderSubject.next(false);
                  this.permissionRequest = false;
                }
              }
            }
          }


        },
        (error) => {
          console.error('Erro ao receber order do servidor WebHook:', error);
        }
      );
      this.disablePullToRefresh()

      this.connection.nameCompany$.subscribe((newNameCompany) => {
        this.nameCompany = newNameCompany
      })
      this.connection.typeParam$.subscribe((newTypeParam) => {
        this.typeParam = newTypeParam
      })
      this.connection.permissionRequestOrder$.subscribe((newPermission) =>{
        this.permissionRequest = newPermission
      })
      this.connection.company$.subscribe((newCompany) =>{
        this.company = newCompany
        this.id_empresa = this.company.company.id_company
      })

      this.apiService.getAuthentication(this.typeParam.qrCodeNumber, this.nameCompany).subscribe((data)=>{
        const authentication = data.response
        if(authentication.cardStatus == 20 || authentication.tableStatus == 20){
          this.connection.permissionRequestOrderSubject.next(false);
          this.permissionRequest = false;
        }
        else{
          this.connection.permissionRequestOrderSubject.next(true);
          this.permissionRequest = true;
        }
      })


      //Verifica se é comanda ou mesa, e ajusta o visual
      if(this.typeParam.type == 1){
        this.verification = 'MESA'
      }
      else if(this.typeParam.type == 2){
        this.verification = 'COMANDA'
      }

      if(this.verification != "")
      {
        if(this.verification.includes('COMANDA'))
        {

          this.numberCard = Number(this.typeParam.numberParam)
          this.nameIdentification = "COMANDA"
          this.alterationString = true;

          this.apiService.getOrderConsumption(Number(this.typeParam.numberParam), 0, this.company.company.id_company).subscribe((response) =>{
            if(!response.orderEmpty){
              const table = response.response.orders[0].table
              this.cartService.idTableCardSubject.next(table.toString())
              this.cacheService.setItemLocal('number_local', table)
              this.tableNumberSelected = table.toString();
              if(this.tableNumberSelected == ''){
                this.alterationButtonModalCall = true;
              }
              else{
                this.alterationButtonModalCall = false;
                this.numberTable = table.toString()
              }
  
              if(response.response.orders.length == 1)
              {
                const session = this.cacheService.getItemLocal('session_id')
  
                if(session)
                {
                  if(session != response.response.orders[0].id_order)
                  {
                    this.router.navigate(['/Authentication']);
                  }
                }
                else{
                  this.cacheService.setItemLocal('session_id', response.response.orders[0].id_order)
                }
              }
            }
            else{
              this.cartService.idTableCard$.subscribe(data =>{
                if(data)
                {
                  this.tableNumberSelected = data.toString();
                  this.numberTable = data
  
                }
              })
              if(this.tableNumberSelected == ''){
                this.alterationButtonModalCall = true;
              }
              else{
                this.alterationButtonModalCall = false;
              }
              const session = this.cacheService.getItemLocal('session_id')
              if(session){
                this.router.navigate(['/Authentication']);
              }
            }

          },
          (error) =>{
            this.router.navigate(['/Authentication']);
          })


        }
        else if(this.verification.includes('MESA')){

          this.nameIdentification = 'MESA'
          this.numberTable = this.typeParam.numberParam.toLocaleString()
          this.alterationString = false;
        }
      }

      //Chama a função para carregar na tela a ordem que esta sendo selecionada
      this.getOrders()


    }

    ngOnDestroy() {
      this.webHookSubscription.unsubscribe();
    }
  //#endregion

  //#region Funções de estilo (Style)

    //Função responsavel por travar de o scroll para que a tela não seja recarregada no menu
    disablePullToRefresh() {
      const element = this.elementRef.nativeElement;
      let isAtTop = true;

      // Adiciona um ouvinte para o evento de scroll
      this.renderer.listen(window, 'scroll', () => {
        // Verifica se a página está no topo
        isAtTop = window.scrollY === 0;
      });

      this.renderer.listen(element, 'touchstart', (event) => {
        // Registra a posição inicial do toque
        const startY = event.touches[0].clientY;

        // Adiciona um ouvinte para o evento de movimento de toque
        const touchMoveListener = this.renderer.listen(element, 'touchmove', (moveEvent) => {
          // Calcula a diferença entre a posição inicial e a posição atual
          const deltaY = moveEvent.touches[0].clientY - startY;

          // Se a diferença for positiva (movimento para baixo) e a página estiver no topo, previne o comportamento padrão
          if (deltaY > 0 && isAtTop) {
            moveEvent.preventDefault();
          }
        });

        // Adiciona um ouvinte para o evento de término de toque
        const touchEndListener = this.renderer.listen(element, 'touchend', () => {
          // Remove os ouvintes após o término do toque
          touchMoveListener();
          touchEndListener();
        });
      });
    }

    //Verifica o tamanho da tela, esconde o Menu superior se necessario
    private checkScreenSize(){
      if(window.innerWidth <= 846){

        this.showTopMenu = false;
      }
      else{
        this.showTopMenu = true;
      }
      this.authLayout.checkScreenSize();
    }

    //Desabilita o scroll da pagina
    disableScroll(){
      this.renderer.setStyle(this.document.body, 'overflow', 'hidden')
    }

    //Abilita o scroll da pagina
    enableScroll(){
      this.renderer.removeStyle(this.document.body, 'overflow');
    }

    // Abre/fecha o collapse de troco
    toggleCollapse(methodPayment: number){
      if(methodPayment == 1)
      {
        this.isCollapsedTroco = !this.isCollapsedTroco;
      }

    }

    // Abre/fecha o collapso de divisão de conta
    buttonsUsers(){
      this.openDivSplit = !this.openDivSplit
    }

    @HostListener('window:resize', [])
    onWindowResize(event: any) {
      this.checkScreenSize();
    }

    //Fecha modal de pedir a conta
    hiddenModal(){
      this.openModalPayment = false
    }

    //Abre o modal de alterar a mesa
    showModalAlterationTable(){
      if(this.permissionRequest){
        this.openModalAlterationTable = true;
        setTimeout(() => {
          this.inputAlterarionTable.nativeElement.focus();
        }, 1);
      }
      else{
        this.toastr.warning('Não é possivel alterar a mesa, por favor aguarde a conta!', 'Pedido em conta!', { positionClass: 'toast-top-right', timeOut: 4000,});
      }

    }

    //Abre o modal de mesa de chamar o garçom
    showModalCallWaiterTable(){
      this.openModalCallTable = true
      setTimeout(() => {
        this.callWatersTable.nativeElement.focus()
      }, 1);
    }

    //Abre o modal de comanda de chamar garçom
    showModalCallWaiterCard(){
      this.openModalCallCard = true
      if(this.numberTable != '')
      {
        setTimeout(() => {
          this.callWaitersCardObs.nativeElement.focus()
        }, 1);

      }
      else{
        setTimeout(() => {
          this.callWaitersCardInput.nativeElement.focus()
        }, 1);
      }

    }

    //Captura o que é digitado nos textArea
    capturaTexArea(event: Event){
      this.textObservationModal = (event?.target as HTMLInputElement).value;
    }

    //Adiciona e edita observações do produto
    adicionarObs(){
      this.order.items
      this.order.items[this.indexCLicked].observations = this.textObservationModal;
      const item = this.order.items[this.indexCLicked]

      const product: Pedido = {
        name: item.product.name,
        id_product: item.product.id_product,
        quantity: item.quantity,
        unitPrice: Number(item.unitPrice),
        totalPrice: Number(item.totalPrice),
        imagem_prod: item.product.image_prod,
        observations: item.observations,
        categories: [],
        baseValue: 0,
        discount: false,
        discountValue: 0,
        oldPrice: 0
      }

      this.orderService.editItemBag(product, this.indexCLicked);
      this.textObservationModal = '';
    }

    warning(){
      this.toastr.warning('Nenhuma forma de pagamento selecionada!!', 'Escolha uma opção!', { positionClass: 'toast-top-right', timeOut: 6000});
    }


  //#endregion

  //#region Funções de lógica (Logic)

    //Calcula o total da ordem para ser montado o objeto e feito o request
    private calcTotalOrder(){
      let total = 0
      this.order.items.forEach(item => {
        total += Number(item.totalPrice)
      });
      return total
    }

    //Puxa a ordem e todos as informações contidas nela para aquela mesa ou comanda
    private getOrders(){
      this.apiService.getOrderConsumption(Number(this.numberCard), Number(this.numberTable), this.id_empresa).subscribe(
        (response) => {
        this.order = response.response.orders[0]
        this.id_Order = this.order.id_order
        this.total = this.calcTotalOrder()
        this.totalDivision = this.total;
        this.requestSucess = false;
        if(!this.order && !this.id_Order){
          this.desabilitButton = true
        }
        else{
          this.desabilitButton = false
        }
        this.temporaryTotal = this.total
      },
      (error) =>
      {
        this.order = this.clearOrder;
        this.total = 0;
        this.requestSucess = false;
        if(!this.order){
          this.desabilitButton = true
          this.alterationButtonModalCall = true;
        }
        else{
          this.desabilitButton = false
        }
      });
    }

    //Volta a pagina
    goback(): void{
      if(this.typeParam.type == 1 || this.typeParam.type == 2)
      {
        this.router.navigate([this.nameCompany, 'P']);
      }
      else{
        this.router.navigate([this.nameCompany]);
      }
    }

    //Divide o preço do pedido pelo numero de pessoas a dividir
    divisionPriceOrder(){
      let ordersPrice = this.total
      this.totalDivision = Number(ordersPrice) / this.counter;
      this.ChangeDetectorRef.detectChanges()
    }

    //Remove um no counter de divisão dos usuarios
    removeInCounter(){
      this.counter = this.counter - 1;
      this.divisionPriceOrder();
      this.ChangeDetectorRef.detectChanges()
    }

    //Adiciona um no counter de divisão dos usuarios
    addInCounter(){
      this.counter = this.counter + 1;
      this.divisionPriceOrder();
      this.ChangeDetectorRef.detectChanges()
    }

    //Soma o troco
    sumChange(value: number){
      this.totalChange = this.totalChange + value;
    }

    //Reseta o troco
    resetChange(){
      this.totalChange = 0;
    }

    //Executa a ação de pedir a conta e bloqueia a aplicação
    requestBill(){
      this.apiService.getAuthentication(this.typeParam.qrCodeNumber, this.nameCompany).subscribe((data)=>{
        const authentication = data.response
        if(authentication.cardStatus == 20 || authentication.tableStatus == 20){
          this.connection.permissionRequestOrderSubject.next(false);
          this.permissionRequest = false;
          this.toastr.error(`Outro usuário ja solicitou a conta, por favor aguarde o garçom!`, 'Pedido em conta!', { positionClass: 'toast-top-right', timeOut: 4000});
        }
        else{
          this.connection.permissionRequestOrderSubject.next(true);
          this.permissionRequest = true;

          const formPayment: FormPaymentLocal = {
            payDivision: this.counter,
            paymentForm: this.selectedPaymentForms,
            id_company: this.id_empresa,
            orderPayStatus: 0,
            changeFor: this.totalChange,
            id_table: this.typeParam.qrCodeNumber
          }

          console.log(formPayment)
          this.apiService.pathRequestBill(this.order.id_order, formPayment).subscribe((response) =>{
            this.connection.permissionRequestOrderSubject.next(false);
            this.bottomMenu.permissionRequest = false;
            this.toastr.success('Aguarde até que o garçom venha até a sua mesa!', 'Success!', { positionClass: 'toast-top-right', timeOut: 5000, progressBar: true });
          })

        }
      })

    }

    //Atualiza a variavel que carrega as formas de pagamento
    updateSelectedPaymentForms(value: number) {
      if(value == 2)
      {
        const form: paymentForm = {
          changeFor: "0",
          onlinePay: 1,
          payStatus: 0,
          paymentForm: value,
          paymentValue: '0'
        }

        this.selectedPaymentForms.push(form)
      }
      else{
        const form: paymentForm = {
          changeFor: "0",
          onlinePay: 1,
          payStatus: 0,
          paymentForm: value,
          paymentValue: '0'
        }

        this.selectedPaymentForms.push(form)
      }

      const length = this.selectedPaymentForms.length
      const valueForpay = Number(this.order.orderPrice) / length

      this.selectedPaymentForms.forEach((form) =>{
        form.paymentValue = valueForpay.toString()
      });
    }

    //Seleciona a forma de pagamento e coloca na lista
    isSelectedPaymentForm(value: number): boolean {
      let registration = false;

      this.selectedPaymentForms.forEach((form) =>{
        if(form.paymentForm == value)
        {
          registration = true;
        }
      })

      return registration

    }

    //Função que chama o garçom
    callWaiter(observation: string, inputValue: string){
      let evento: event;

      if(inputValue != '0')
      {
        this.cacheService.setItemLocal('number_local', inputValue)
        this.tableNumberSelected = inputValue;
        this.cartService.idTableCardSubject.next(inputValue)
        // this.valorInputModal = inputValue;
        this.alterationButtonModalCall = false;
      }


      if(this.order){
        evento = {
          eventType: 1,
          id_company: this.id_empresa.toString(),
          id_order: this.order.id_order.toString(),
          tableNumber: this.order.table,
          eventObservation: observation
        }
      }
      else{
        evento = {
          eventType: 1,
          id_company: this.id_empresa.toString(),
          id_order: '',
          tableNumber: Number(this.tableNumberSelected),
          eventObservation: observation
        }
      }

      this.textObservationWaiter = '';
      this.countText = 0;
      this.openModalCallCard = false;
      this.openModalCallTable = false;
      this.apiService.events(evento).subscribe((response) =>{
        console.log(response)
        this.toastr.success('Aguarde até que o garçom venha até a sua mesa', 'Sucesso', { positionClass: 'toast-top-right', timeOut: 5000, progressBar: true });
      })
    }

    receivedEvent(event: Event){
      const inputElement = event?.target as HTMLInputElement;

      if (inputElement.value.length <= 48) {
        this.textObservationWaiter = inputElement.value;
        this.countText = this.textObservationWaiter.length;
      } else {
        inputElement.value = this.textObservationWaiter;
      }
    }

    //Função que altera a mesa
    alterationTableNumber(inputValue: string){
      if(this.order && this.order.id_order)
      {
        this.apiService.pathAlterarionTableNumber(this.order.id_order, inputValue, this.id_empresa.toString()).subscribe((response) =>{
          this.toastr.success('Mesa alterada com sucesso!', 'Success!', { positionClass: 'toast-top-right', timeOut: 2000, progressBar: true });
          this.cacheService.setItemLocal('number_local', inputValue)
          this.tableNumberSelected = inputValue;
          this.cartService.idTableCardSubject.next(inputValue)
          this.alterationButtonModalCall = false;
          // this.valorInputModal = inputValue;
          this.numberTable = inputValue
        })
      }
      else{
        // this.valorInputModal = inputValue;
        this.numberTable = inputValue
        this.cartService.idTableCardSubject.next(inputValue)
        this.alterationButtonModalCall = false;
        this.tableNumberSelected = inputValue;
        this.cacheService.setItemLocal('number_local', inputValue)
        this.toastr.success('Mesa alterada com sucesso!', 'Success!', { positionClass: 'toast-top-right', timeOut: 2000, progressBar: true });
      }
      this.openModalAlterationTable = false;

    }
  //#endregion
}
