
<div class="container">
    <div class="navegation-header d-flex align-items-center">
        <div class="box-icon" (click)="goBack()">
          <i class="fa fa-chevron-left" aria-hidden="true"></i>
        </div>
        <div class="box-title">
          <h3 style="margin-bottom: 0px;">Cupons</h3>
        </div>
    </div>
</div>
<div class="title">
  <h4>Meus Cupons</h4>
</div>
<div class="box-coupons">
  <ng-container *ngFor="let coupon of this.couponsUser; let i = index">
    <div class="coupon-option">
      <div class="content">
        <div class="coupon-icon">
          <img src="./../../../../assets/icons/cupom.png" />
        </div>
        <div class="coupon">
          <ng-container *ngIf="coupon.couponValuePercent != 0">
            <div class="coupon-title">
              {{ coupon.couponValuePercent }}% OFF
            </div>
          </ng-container>

          <ng-container *ngIf="coupon.couponValueCash != '0.00'">
            <div class="coupon-title">
              {{ coupon.couponValueCash | currency: 'BRL' }} OFF
            </div>
          </ng-container>

          <div class="coupon-content">
            <div class="coupon-rules">
              Pedido min {{ coupon.couponMinValue | currency: 'BRL' }}
            </div>
            <div class="coupon-expires">
              Expira em: {{ this.datesFormatted[i] }}
            </div>
          </div>
        </div>
      </div>
  
      <!-- <div class="coupon-more">
        <i class="fa-solid fa-chevron-right"></i>
      </div> -->
    </div>
  </ng-container>

</div>
