import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, Injectable, Input, OnInit, Renderer2, ViewChild, } from '@angular/core';
import { Pedido } from 'src/app/Interfaces/pedido';
import { DOCUMENT } from '@angular/common';
import { Subject, Subscription, combineLatest} from 'rxjs';
import { CartService } from '../../shared/services/Cart/cart.service';
import { Router } from '@angular/router';
import { AuthLayoutComponent } from '../../shared/components/layout/auth-layout/auth-layout.component';
import { CacheService } from '../../shared/services/Cache/cache.service';
import { OrderService } from '../../shared/services/Order/order.service';
import { ToastrService } from 'ngx-toastr';
import { Company, ConnectionService} from 'src/app/shared/services/Connection/connection.service';
import { ApiService } from 'src/app/shared/services/API/api.service';
import { WebhookService } from 'src/app/shared/services/WeebHook/webhook.service';
import { typeInterface } from 'src/app/Interfaces/company';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css', "../../../assets/styles/global/_modal.scss"]
})

@Injectable({
  providedIn: 'root'
})

export class CartComponent implements OnInit, AfterViewInit {

  @Input() notSideBar = false;
  private destroy$: Subject<void> = new Subject<void>();

  //#region Variaveis de elemento HTML
    @ViewChild('btnNegative') btnNegative!: ElementRef;
    /*Inputs*/
    @ViewChild('inputConfirmTable') inputConfirmTable!: ElementRef;
    @ViewChild('inputAlterationTable') inputAlterarionTable!: ElementRef;
  //#endregion

  //#region Variaveis de estilo
    showTopMenu = true;
    activeButton = false;
    openModalTable = false;
    openModalTwo = false;
    openModalDelete = false;
    alterationString = false;
    openModaladdObs = false;
    openModalEditObs = false;
    openModalCard = false;
    openModalCardAlteration = false;
    isZoom = false;
    openModalConfirm = false;


  //#endregion

  //#region Variaveis Locais

    //Variaveis Privadas
    private webHookSubscription!: Subscription;

    //Variaveis booleanas
    permissionRequest = true;
    userConfirmation!: boolean;

    //Variaveis String
    logoCompany!: string;
    tableNumberSelected: string = '';
    textObservationModal: string = '';
    nameEmpresa: string = '';
    verification: string = '';
    nameIdentification: string = '';
    nameCompany: string = '';
    typeParam!: typeInterface;
    inputValueAlteration: string = '';
    valueInputTable!: number;
    nameButton = "";
    id_company = '';
    flavorQuantityatt: number[] = []
    flavorQuantity: number[] = []

    //Variaveis Numericas
    baseValue: number = 0;
    numberDeleteIndex: number = 0;
    total: number = 0;
    counter: number = 1;
    idOrder: number = 0;
    showLogo = 0;
    indexCLicked!: number;

    //Variaveis de Objetos
    bag: Pedido[] = []
    company!: Company;
  //#endregion

  //#region Inicializadores
    constructor(
      private cdRef: ChangeDetectorRef,
      private cartService: CartService,
      private router: Router,
      private cacheService: CacheService,
      private orderService: OrderService,
      private authLayout: AuthLayoutComponent,
      private ChangeDetectorRef: ChangeDetectorRef,
      private connection: ConnectionService,
      private toastr: ToastrService,
      private apiService: ApiService,
      private webHookService: WebhookService,
      private renderer: Renderer2,
      private elementRef: ElementRef,
      @Inject(DOCUMENT) private document: Document,
      ){
        window.scrollTo({
          behavior: 'instant' as ScrollBehavior,
          top: 0,
          left: 0
        })

        this.checkScreenSize();
    }


    //Executado assim que é iniciado o component
    ngOnInit(): void {
      this.disablePullToRefresh()

      //#region Conexão e recebimento do webhook

      this.webHookSubscription = this.webHookService.getUpdates().subscribe(
        (content: any) => {

          if(content.type == 4)
          {
            const data = content.data

            if(data.webhooks){
              const counter = data.webhooks.length -1
              this.tableNumberSelected = data.webhooks[counter].tableNumber.toString()
            }
          }
        },
        (error) => {
          console.error('Erro ao receber order do servidor WebHook:', error);
        }
      );

      //#endregion

      //#region Subscrições

      combineLatest([
        this.connection.nameCompany$,
        this.connection.typeParam$,
        this.connection.company$,
        this.connection.user$,
        this.connection.permissionRequestOrder$,
        this.cartService.idTableCard$
      ]).subscribe(([newNameCompany, newtypeParam, newCompany, User, newPermission, idTable]) => {
        
        //Nome da compania
        this.nameCompany = newNameCompany;

        //Objeto da compania
        this.company = newCompany
        this.id_company = this.company.company.id_company.toString();
        this.nameEmpresa = this.company.company.companyName;
        this.logoCompany = this.company.company.logo;


        //Verifica se ja tem usuario salvo em cache
        if(User)
        {
          this.userConfirmation = true;
        }
        else if(this.cacheService.getItemLocal('user_key'))
        {
          this.connection.getUser();
        }
        else{
          this.userConfirmation = false
        }

        //tipo do parametro do QrCode
        this.typeParam = newtypeParam;

        if(this.typeParam.type == 2){
          this.tableNumberSelected = idTable;
        }

        //Verifica se é possivel fazer request e o pedido não esta em conta
        this.permissionRequest = newPermission

      });

      //#endregion

      //#region Verifica se é Comanda e faz a lógica necessaria
      if(this.typeParam.type == 1){
        this.verification = 'MESA'
      }
      else if(this.typeParam.type == 2){
        this.verification = 'COMANDA'
      }

      if(this.verification.includes('COMANDA'))
      {
        //Um request do consumo para saber os valores de mesa ids de orders
        this.apiService.getOrderConsumption(Number(this.typeParam.numberParam), 0, this.company.company.id_company).subscribe((response) =>{
          if(!response.orderEmpty)
          {
            const table = response.response.orders[0].table
            this.idOrder = response.response.orders[0].id_order
            this.cartService.idTableCardSubject.next(table.toString())
            this.cacheService.setItemLocal('number_local', table)
            if(table != 0){
              this.valueInputTable = table; 
            }

            this.tableNumberSelected = table.toString();
  
            //Faz a autenticação para verificar se a pessoa esta tentando fazer pedido mesmo com a sesão expirada
            if(response.response.orders.length == 1)
            {
              const session = this.cacheService.getItemLocal('session_id')
  
              if(session)
              {
                if(session != response.response.orders[0].id_order)
                {
                  this.router.navigate(['/Authentication']);
                }
              }
              else{
                this.cacheService.setItemLocal('session_id', response.response.orders[0].id_order)
              }
            }
          }

        },
        (error) =>{
          this.cartService.idTableCard$.subscribe(data =>{
            if(data)
            {
              this.cacheService.setItemLocal('number_local', data)
              if(data != '0'){
                this.valueInputTable = Number(data);
              }

              this.tableNumberSelected = data.toString();
            }
          })
          const session = this.cacheService.getItemLocal('session_id')
          if(session){
            this.router.navigate(['/Authentication']);
          }
        })
      }
      //#endregion

      //#region Resgata o parametro que tiver e ajusta o visual de acordo com o que vier nele
      if(this.typeParam.type == 1 || this.typeParam.type == 2)
      {
        this.nameButton = "Finalizar";
      }
      else{
        this.nameButton = "Avançar";
      }
      //#endregion

      //#region Verifica se a url esta entrando em uma mesa ou uma comanda
      if(this.verification != "")
      {
        if(this.verification.includes('COMANDA'))
        {
          this.nameIdentification = "COMANDA"
          this.alterationString = true
        }
        else if(this.verification.includes('MESA')){

          this.nameIdentification = 'MESA'
          this.alterationString = false;
        }
      }
      //#endregion

      //#region Resgata todos os produtos que estiverem no carrinho da pessoa e faz o calculo total
      this.bag = this.cartService.getCartItems();
      this.total = this.calcTotalOrder()

      let count = 0
      this.bag.forEach((product, index) =>{
        count = 0
        product.categories.forEach(categorie =>{
          let value = 0
          categorie.additional.forEach(additional =>{
            value += additional.quantitySub
            if(categorie.flavorCategory == 1)
            {
              count += additional.quantitySub
            }
          })
        })
        this.flavorQuantityatt.push(count)
        this.flavorQuantity.push(index)
      })
      //#endregion

      this.desabilitButton();
      this.showLogo = this.bag.length;
    }

    ngAfterViewInit(): void{}

    ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
      this.webHookSubscription.unsubscribe();
    }


  //#endregion

  //#region Funções de estilo (Style)


    //Função responsavel por travar de o scroll para que a tela não seja recarregada no menu
    disablePullToRefresh() {
      const element = this.elementRef.nativeElement;
      let isAtTop = true;

      // Adiciona um ouvinte para o evento de scroll
      this.renderer.listen(window, 'scroll', () => {
        // Verifica se a página está no topo
        isAtTop = window.scrollY === 0;
      });

      this.renderer.listen(element, 'touchstart', (event) => {
        // Registra a posição inicial do toque
        const startY = event.touches[0].clientY;

        // Adiciona um ouvinte para o evento de movimento de toque
        const touchMoveListener = this.renderer.listen(element, 'touchmove', (moveEvent) => {
          // Calcula a diferença entre a posição inicial e a posição atual
          const deltaY = moveEvent.touches[0].clientY - startY;

          // Se a diferença for positiva (movimento para baixo) e a página estiver no topo, previne o comportamento padrão
          if (deltaY > 0 && isAtTop) {
            moveEvent.preventDefault();
          }
        });

        // Adiciona um ouvinte para o evento de término de toque
        const touchEndListener = this.renderer.listen(element, 'touchend', () => {
          // Remove os ouvintes após o término do toque
          touchMoveListener();
          touchEndListener();
        });
      });
    }

    //Esconde o botão de avançar se o carrinho estiver vazio
    private desabilitButton(){
      if(this.bag.length <= 0)
      {
        this.activeButton = false;
      }
      else{
        this.activeButton = true;
      }
    }

    //Verifica o tamanho da tela, esconde o Menu superior se necessario
    private checkScreenSize(){
      if(window.innerWidth <= 846){

        this.showTopMenu = false;
      }
      else{
        this.showTopMenu = true;
      }
      this.authLayout.checkScreenSize();
    }

    //Abre o modal de comanda para inserir a mesa
    private showModalCard(){
      this.openModalCard = true;
      this.ChangeDetectorRef.detectChanges();
      if(!this.valueInputTable){
        setTimeout(() => {
          this.inputConfirmTable.nativeElement.focus()
        }, 1);
      }
    }

    //Abre o modal de mesa
    private showModalTable(){
      this.openModalTable = true;
      this.ChangeDetectorRef.detectChanges();
    }

    showModalConfirm(){
      this.openModalConfirm = true;
    }

    sucessModalConfirm(){
      if(this.typeParam.type == 1){
        this.AssembleOrder(Number(this.typeParam.numberParam));
      }
      else{
        this.AssembleOrder(Number(this.tableNumberSelected))
      }
      this.openModalConfirm = false;
    }

    //Abre o modal para alterar a mesa da comanda
    showModalCardAlterarion(){
      if(this.permissionRequest){
        this.openModalCardAlteration = true;
        setTimeout(() => {
          this.inputAlterarionTable.nativeElement.focus();
        }, 1);
      }
      else{
        this.toastr.warning('Não é possivel alterar a mesa, por favor aguarde a conta!', 'Pedido em conta!', { positionClass: 'toast-top-right', timeOut: 2000,});
      }
    }

    //Captura o que é digitado nos textArea
    capturaTexArea(event: Event){
      this.textObservationModal = (event?.target as HTMLInputElement).value;
    }

    //Abre o modal para deletar um item do carrinho
    showModalDelete(i: number){
      this.openModalDelete = true;
      this.numberDeleteIndex = i;
    }

    @HostListener('window:resize', [])
    onWindowResize(event: any) {
      this.checkScreenSize();
    }

  //#endregion

  //#region Funções de lógica (Logic)

    //Calcula o total de todos os itens que estão no carrinho
    private calcTotal(){
      for(var i = 0; i < this.bag.length; i++){

        this.total += this.bag[i].totalPrice;
        this.cdRef.detectChanges()

      }
    }

    //remove a cor do botão de subtração se o counter <= 0
    removeOneInCounter(item: any, index: number){
      if (item.quantity > 0) {
        item.quantity--;
        
        if(item.pizza == 1){
          item.flavorsValue = item.baseFlavorsValue * item.quantity
        }
      }

      if(this.counter >= 1){
        this.btnNegative.nativeElement.classList.add('activated')
      }
      else{
        this.btnNegative.nativeElement.classList.remove('activated')
      }
      item.totalPrice = item.quantity * item.baseValue
      this.total = 0;
      this.calcTotal();
      this.cdRef.detectChanges()
      this.cartService.editItemCart(item, index)
    }

    //retorna a pagina anterior
    goback(): void{
      if(this.typeParam.type == 1 || this.typeParam.type == 2)
      {
        this.router.navigate([this.nameCompany, 'P']);
      }
      else if(this.typeParam.type == 3)
      {
        this.router.navigate([this.nameCompany, 'B']);
      }
      else{
        this.router.navigate([this.nameCompany]);
      }
    }

    //adiciona cor ao botão de subtração se o counter > 0
    addOneInCounter(item: any, index: number){
      item.quantity++

      if(item.pizza == 1){
        item.flavorsValue = item.baseFlavorsValue * item.quantity
      }

      item.totalPrice = item.quantity * item.baseValue
      this.total = 0;
      this.calcTotal();
      this.cdRef.detectChanges()

      this.cartService.editItemCart(item, index)
    }

    //Limpa o carrinho
    clearCart(){
      this.openModalTwo = false;
      this.cartService.clearCart();
      this.bag = [];
      this.total = 0;
      this.desabilitButton();
      this.toastr.success('Carrinho esvaziado com sucesso!', 'Success!', { positionClass: 'toast-top-right', timeOut: 1500});

    }

    //Deleta um item especifico do carrinho
    Delete(){
      this.cartService.removeToCart(this.numberDeleteIndex)
      this.cacheService.setItemSession('temporary-counter', this.cacheService.getItemLocal('product-cart').length)
      this.total = this.calcTotalOrder();
      this.toastr.success('Produto excluido com sucesso!', 'Sucesso!', { positionClass: 'toast-top-right', timeOut: 1500,});
    }

    //Logica para alterar a mesa da comanda
    alterationTableNumber(inputValue: string){
      setTimeout(() => {
        this.inputConfirmTable.nativeElement.focus()
      }, 1);


      if(this.idOrder)
      {
        this.apiService.pathAlterarionTableNumber(this.idOrder, inputValue, this.id_company).subscribe((response) =>{
          this.toastr.success('Mesa alterada com sucesso!', 'Success!', { positionClass: 'toast-top-right', timeOut: 2000, progressBar: true });
          this.cacheService.setItemLocal('number_local', inputValue)
          this.tableNumberSelected = inputValue;
          this.cartService.idTableCardSubject.next(inputValue)
        })
      }
      else{
        this.cartService.idTableCardSubject.next(inputValue)
        this.tableNumberSelected = inputValue;
        this.cacheService.setItemLocal('number_local', inputValue)
        this.toastr.success('Mesa alterada com sucesso!', 'Success!', { positionClass: 'toast-top-right', timeOut: 2000, progressBar: true });
      }
      this.openModalCardAlteration = false;

    }

    //captura a id do elemento clicado e redireciona para rota do editar o produto
    capturaId(id: number, item: Pedido, index: number){
      this.cartService.productEditionSubject.next(item)
      this.cartService.indexItem = index;
      if(this.nameCompany == '')
      {
        this.connection.nameCompany$.subscribe({
          next: (newNameCompany: string) => {
            // console.log('Novo nome da empresa recebido:', newNameCompany);
          },
          error: (error) => {
            // console.error('Erro ao receber nome da empresa:', error);
          },
          complete: () => {
            // console.log('Completado');
          }
        });

        if(this.nameCompany == '')
        {
          this.nameCompany = this.cacheService.getItemLocal('name_cache_key')
        }
      }
      this.router.navigate([this.nameCompany, 'edit-product', id])

    }

    //#region Montar e fazer o pedido

      //Faz a verificação se for usuario, redireciona para confirmação de pedido, se for mesa ou comanda, executa outra ação!
      Next(){

        // document.body.removeAttribute('style');

        this.orderService.keepBag(this.bag)

        if(this.typeParam.type == 1 || this.typeParam.type == 2)
        {
          this.apiService.getAuthentication(this.typeParam.qrCodeNumber, this.nameCompany).subscribe((data)=>{
            const authentication = data.response
            if(authentication.cardStatus == 20 || authentication.tableStatus == 20){
              this.connection.permissionRequestOrderSubject.next(false);
              this.permissionRequest = false;
            }
            else{
              this.connection.permissionRequestOrderSubject.next(true);
              this.permissionRequest = true;
            }
          })

          if(this.permissionRequest){
            if(this.typeParam.type == 1)
            {
              if(this.company.configs.localConfirmation == 0){
                this.AssembleOrder(Number(this.typeParam.numberParam));
              }
              else{
                this.showModalConfirm();
              }

            }
            else{
              if(this.company.configs.cardConfig == 0){
                if(this.company.configs.localConfirmation == 0){
                  this.AssembleOrder(0)
                }
                else{
                  this.showModalConfirm();
                }

              }
              else{
                if(!this.tableNumberSelected){
                  this.showModalCard();
                }
                else{
                  if(this.company.configs.localConfirmation == 0){
                    this.AssembleOrder(Number(this.tableNumberSelected))
                  }
                  else{
                    this.showModalConfirm();
                  }

                }

              }
            }
          }
          else{
            this.toastr.error(`Não é possivel fazer pedido, aguarde o garçom!`, 'Pedido em conta!', { positionClass: 'toast-top-right', timeOut: 4000});
          }
        }
        else if(this.typeParam.type == 3){
          if(this.userConfirmation)
          {
            this.router.navigate([ this.nameCompany, 'order-confirmation-counter'])
          }
          else{
            this.router.navigate([ this.nameCompany, 'user-data'])
          }

        }
        else{
          if(this.userConfirmation)
          {
            this.router.navigate([ this.nameCompany, 'order-confirmation'])
          }
          else{
            this.router.navigate([ this.nameCompany, 'user-data'])
          }
        }

      }

      //Calcula o total da ordem para ser montado o objeto e feito o request
      private calcTotalOrder(){
        let total = 0
        this.bag.forEach(product => {
          total += product.totalPrice
        });
        return total
      }

      //Monta o objeto de pedido se for mesa ou comanda
      async AssembleOrder(tableNumber: number): Promise<void> {
        try {
          this.orderService.AssembleLocalOrder(tableNumber, this.permissionRequest, this.typeParam.type)
        } catch (error) {
          console.error("Erro ao montar o pedido:", error);
        }  
      }

    //#endregion

  //#endregion

}
