import { Injectable } from '@angular/core';
import { CacheService } from '../Cache/cache.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Cliente } from 'src/app/Interfaces/customer';

@Injectable({
  providedIn: 'root'
})
export class UserService {

 userEmpty!: Cliente

 userSubject: BehaviorSubject<Cliente> = new BehaviorSubject<Cliente>(this.userEmpty);
 user$: Observable<Cliente> = this.userSubject.asObservable();

 editingUserSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
 editingUser$: Observable<boolean> = this.editingUserSubject.asObservable();

  constructor(private cacheService: CacheService) { }


  Usuario(){
    let user = this.cacheService.getItemLocal('user_key')
  }


}
