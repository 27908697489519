import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ApiService } from '../../services/API/api.service';
import { ConnectionService } from '../../services/Connection/connection.service';
import { Observable } from 'rxjs';
import { Coupon, CouponUser } from 'src/app/Interfaces/coupon';
import { Router } from '@angular/router';
import { Order } from 'src/app/Interfaces/order';
import { OrderUser } from 'src/app/Interfaces/pedido';
import { SharedService } from '../../services/shared/shared.service';
import { Cliente } from 'src/app/Interfaces/customer';
import { DatePipe } from '@angular/common';
import { AuthLayoutComponent } from '../layout/auth-layout/auth-layout.component';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'app-cupom',
  templateUrl: './cupom.component.html',
  styleUrl: './cupom.component.css'
})
export class CupomComponent implements OnInit{
  // @Input() orderPrice: any;
  // @Input() productsOrder: any;
  // @Input() id_customer: any;
  // @Input() typeOrder!: number;
  @Input() order!: OrderUser
  @Output() coupon: EventEmitter<{ check: boolean, coupon: Coupon}> = new EventEmitter<{ check: boolean, coupon: Coupon}>();
  @Output() view: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  id_company!: number;
  dayOfWeek!: number;
  orderPrice: number = 0;
  cupomDigit: string = ''
  cupomEmpty!: Coupon;
  minValue = false
  stringCoupomEmpty = false
  nameCompany: string = '';
  openView: boolean = false;
  user!: Cliente;

  coupomNotValid!: Coupon | undefined;
  couponSelected!: Coupon;
  cupomselecionado = false;
  couponsUserValid: CouponUser[] = []
  couponsUserInvalid: CouponUser[] = []
  datesFormatted: string[] = []
  viewSelected: number = 1;
  notCouponsUserValid: boolean = false;
  notCouponsUserInvalid: boolean = false;
  showTopMenu: boolean = false;
  alteration = false;
  labelNotApliqued: string = '';

  constructor(
    private apiService: ApiService,
    private connection: ConnectionService,
    private sharedService: SharedService,
    private authLayout: AuthLayoutComponent,
    private toastr: ToastrService,
  ){
    this.connection.company$.subscribe((newCompany) =>{
      this.id_company = newCompany.company.id_company
    })

    this.connection.nameCompany$.subscribe((newNameCompany) => {
      this.nameCompany = newNameCompany
    })

    this.sharedService.methodReceipt$.subscribe((data) =>{
      if(data == true){
        this.cupomselecionado = false;
        this.coupon.emit({ check: false, coupon: this.cupomEmpty});
        this.cupomDigit = '';
        this.minValue = false;
      }
    })

    this.connection.user$.subscribe((data) =>{
      if(data){
        this.user = data;
        // console.log(this.user)
      }
    })
    this.getDay();
  }

  ngOnInit(): void {
 
    if(this.order.deliveryFee && this.order.id_address){
      this.orderPrice = this.order.orderPrice - this.order.deliveryFee
    }
    else{
      this.orderPrice = this.order.orderPrice
    }

    this.filterCoupons()

  }

  ngAfterViewInit(): void {
    this.checkScreenSize()
  }

  //Responsavel por verificar o cupom e retornar se ele é valido ou não
  aplicationCoupom(cupom: string, type: number){
    if(cupom != ''){
      this.apiService.VerifiCoupon(cupom, this.id_company, this.dayOfWeek, this.order.products, this.order.id_customerFK).subscribe(
        (data) =>{
          if(!data.empty){
  
            if(!data.expired){

              if(data.deliveryFree){

                if(this.order.orderType == 5){

                  if(this.order.id_address){

                    if(data.minValue != 0){
  
                      if(this.orderPrice >= data.minValue){

                        this.couponSelected = data;
                        this.cupomselecionado = true;
                        this.openView = false;
                        this.view.emit(false);
                        this.EmitEventCoupomSelected(this.couponSelected);
                        this.toastr.success(`Cupom aplicado`, 'Sucesso', { positionClass: 'toast-top-right', timeOut: 3000});
                      }
                      else{
                        this.coupomNotValid = data;
                        this.cupomselecionado = false;
                        this.minValue = true
                      }
                    }
                    else{
                      this.couponSelected = data;
                      this.cupomselecionado = true;
                      this.openView = false;
                      this.view.emit(false);
                      this.EmitEventCoupomSelected(this.couponSelected);
                      this.toastr.success(`Cupom aplicado`, 'Sucesso', { positionClass: 'toast-top-right', timeOut: 3000});
                    }
                  }
                  else{
                    if(type == 1){
                      this.coupomNotValid = data;
                      this.cupomselecionado = false;
                      this.labelNotApliqued = 'Selecione o endereço de entrega primeiro'
                    }
                    else{
                      this.alteration = !this.alteration
                      this.toastr.clear();
                      this.toastr.warning(`Selecione o endereço de entrega primeiro`, 'Atenção', { positionClass: 'toast-top-right', timeOut: 4000});
                    }
                    
                  }
                }
                else{
                  this.cupomselecionado = false;
                  if(type == 1){
                    this.coupomNotValid = data;
                    this.labelNotApliqued = 'Cupom válido apenas para entrega.'
                  }
                  else{
                    this.alteration = !this.alteration
                    this.toastr.clear();
                    this.toastr.warning(`Cupom válido apenas para entrega.`, 'Atenção', { positionClass: 'toast-top-right', timeOut: 4000});
                  }
                  
                }
              }
              else if(!data.deliveryFree){

                if(data.minValue != 0){
  
                  if(this.orderPrice >= data.minValue){
                    this.couponSelected = data;
                    this.cupomselecionado = true;
                    this.openView = false;
                    this.view.emit(false);
                    this.EmitEventCoupomSelected(this.couponSelected);
                    this.toastr.success(`Cupom aplicado`, 'Sucesso', { positionClass: 'toast-top-right', timeOut: 3000});
                  }
                  else{
                    this.coupomNotValid = data;
                    this.cupomselecionado = false;
                    this.minValue = true
                  }
                }
                else{
                  this.couponSelected = data;
                  this.cupomselecionado = true;
                  this.openView = false;
                  this.view.emit(false);
                  this.EmitEventCoupomSelected(this.couponSelected);
                  this.toastr.success(`Cupom aplicado`, 'Sucesso', { positionClass: 'toast-top-right', timeOut: 3000});
                }

              }
            }
            else{
              this.coupomNotValid = data;
              this.labelNotApliqued = 'Cupom expirado'
              this.cupomselecionado = false;
            }
          }
          else{
            this.coupomNotValid = data
            this.cupomselecionado = false;
            if(this.coupomNotValid)
            if(this.coupomNotValid.empty){
              this.labelNotApliqued = 'Cupom inválido.'
            }

          }
  
        },
        (error) =>{
          console.log(error);
        }
      )
    }
    else{
      this.stringCoupomEmpty = true;
    }
  }

  getDay(){
    this.apiService.getHours().subscribe(
      (data) =>{
        const date = new Date(data.date)
        this.dayOfWeek = date.getDay();
      },
      (error) =>{}
    )
  }

  //Quando o endereço é selecionado
  EmitEventCoupomSelected(cupom: Coupon) {
    this.coupon.emit({ check: true, coupon: cupom});
  }

  //Quando o botão de alterar e acionado
  changeButton(){
    this.labelNotApliqued = '';
    this.cupomselecionado = false;
    this.coupon.emit({ check: false, coupon: this.cupomEmpty});
    this.cupomDigit = '';
    this.minValue = false;
    this.openView = true;
    this.view.emit(true);
    this.coupomNotValid = undefined;
  }

  checkChange(event: any){
    this.coupomNotValid = this.cupomEmpty;
    this.stringCoupomEmpty = false;
  }

  validationExpires(date: string): boolean{
    const dateExpires = new Date(date);
    const dateNow = new Date();

    return dateExpires < dateNow;
  }

  @HostListener('window:resize', [])
  onWindowResize(event: any) {
    this.checkScreenSize();
  }

  //Verifica o tamanho da tela, esconde o Menu superior se necessario
  checkScreenSize(){
    if(window.innerWidth <= 846){

      this.showTopMenu = false;
    }
    else{
      this.showTopMenu = true;
    }
    this.authLayout.checkScreenSize();
  }



//#region View

  //para  voltar para o order-confirmation
  goBack(){
    this.cupomDigit = '';
    this.coupomNotValid = undefined;
    this.stringCoupomEmpty = false;
    this.minValue = false;
    this.labelNotApliqued = '';
    this.openView = false;
    this.view.emit(false);
  }

  //Ao abrir a view de cupons
  viewOpen(){
    this.openView = true;
    this.view.emit(true);
    // setTimeout(() => {
    //   const scrollTop = 0;
    //   window.scrollTo({ top: scrollTop, behavior: 'auto'});
    // }, 2000);

  }

  //Acionado ao clicar no card
  changeCheckBox(event: any){
    const data = event
    if(data.checked){
      this.aplicationCoupom(data.value, 2);
    }
  }

  //troca de view entre disponiveis e indisponiveis
  changeView(view: number){
    this.viewSelected = view
  }

  //Calcula quantos dias falta para expirar o cupom
  calcDaysMissing(dataAlvoISO: string, dataAtual: string): number{
    const dataAlvo = new Date(dataAlvoISO);

    const today = new Date(dataAtual)

    // Calcula a diferença em milissegundos e converte para dias
    const diffTime = dataAlvo.getTime() - today.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
  }


  //Filtra os cupons válidos e os iválidos
  filterCoupons(){
    if(this.user.coupons && this.user.coupons.length != 0){
      console.log(this.user)
      this.user.coupons.forEach(coupon =>{
        if(this.validationExpires(coupon.couponExpires)){
          this.couponsUserInvalid.push(coupon)
        }
        else{
          if(coupon.couponType == 2){
            if(this.validCouponProducts(coupon.productsDiscount)){
              coupon.valid = true
            }
            else{
              coupon.valid = false;
            }
            this.couponsUserValid.push(coupon)
          }
          else{
            coupon.valid = true;
            this.couponsUserValid.push(coupon)
          }

        }
      })

      this.apiService.getHours().subscribe(
        (data) =>{
          this.couponsUserValid.forEach(coupon => {
            const dataExpires =  this.calcDaysMissing(coupon.couponExpires, data.date)
    
            if(dataExpires){
              this.datesFormatted.push(dataExpires.toString())
            }
    
          });
        },
        (error) =>{}
      )

      if(this.couponsUserValid.length > 0){
        this.notCouponsUserValid = false;
      }
      if(this.couponsUserInvalid.length > 0){
        this.notCouponsUserInvalid = false;
      }
    }
    else{
      this.notCouponsUserValid = true;
      this.notCouponsUserInvalid = true;
    }
  }


  validCouponProducts(productsDiscount: any[]){
    if(productsDiscount.length != 0){
      for (let i = 0; i <= productsDiscount.length; i++) {
        const valid = this.order.products.find(product => product.id_product == productsDiscount[i])
        if(valid){
          return true
        } 
      }
      return false
    }
    else{
      return false
    }

  }

//#endregion

}
