import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CouponUser } from 'src/app/Interfaces/coupon';
import { Cliente } from 'src/app/Interfaces/customer';
import { ConnectionService } from 'src/app/shared/services/Connection/connection.service';

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrl: './coupons.component.css'
})
export class CouponsComponent implements OnInit, AfterViewInit{

  nameCompany: string = '';
  user!: Cliente;
  couponsUser: CouponUser[] = []
  datesFormatted: string[] = []

  constructor(private router: Router, private connection: ConnectionService, private datePipe: DatePipe){
    this.connection.nameCompany$.subscribe((newNameCompany) => {
      this.nameCompany = newNameCompany
    })
    this.connection.user$.subscribe((data) =>{
      if(data){
        this.user = data;
      }
    })
  }

  ngOnInit(): void {

    if(this.user.coupons && this.user.coupons.length != 0){
      this.couponsUser = this.user.coupons
      this.couponsUser.forEach(coupon => {
        const dataExpires = this.datePipe.transform(coupon.couponExpires, `dd/MM/yyyy`);

        if(dataExpires){
          this.datesFormatted.push(dataExpires)
        }

      });
      console.log(this.couponsUser)
    }
  }

  ngAfterViewInit(): void {
      
  }


  goBack(){
    this.router.navigate([this.nameCompany, 'user']);
  }
}
