import { Component, EventEmitter, Inject, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { Company, ConnectionService } from '../../services/Connection/connection.service';
import { ApiService } from '../../services/API/api.service';
import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';
import { GeolocationService } from '../../services/GeoLocation/geolocation.service';
import { Router } from '@angular/router';
import { EventsService } from '../../services/events/events.service';
import { CacheService } from '../../services/Cache/cache.service';
import { Address, Cliente } from 'src/app/Interfaces/customer';

@Component({
  selector: 'app-address-card',
  templateUrl: './address-card.component.html',
  styleUrls: ['../../../../assets/styles/global/_card.css', './address-card.component.css', '../../../../assets/styles/global/_modal.scss']
})
export class AddressCardComponent implements OnInit {

  openModalDelete = false;
  step = false;
  showLoading = false;
  toastActive: boolean = false;

  nameCompany: string = '';
  id_Address: string = ''

  user!: Cliente;
  company!: Company;
  addressEmpty!: Address;
  addressOption!: Address;
  selectedOption: { checked: boolean, value: number } = { checked: false, value: 0};

  @Input() orderValue: number = 0;
  @Input() methodReceipt: { checked: boolean, value: number } = {checked: false, value: 0}
  @Output() addressSelected: EventEmitter<{ checked: boolean, value: number, address: Address}> = new EventEmitter<{ checked: boolean, value: number, address: Address}>();

  constructor(private geolocation: GeolocationService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private Document: Document,
    private connection: ConnectionService,
    private apiService: ApiService,
    private toastr: ToastrService,
    private router: Router,
    private eventsService: EventsService,
    private cacheService: CacheService
    )
  {}

  ngOnInit(): void {
    if(this.selectedOption.checked == false)
    {
      this.step = true;
    }
    this.connection.nameCompany$.subscribe((newNameCompany) => {
      this.nameCompany = newNameCompany;
    })
    this.connection.company$.subscribe((newCompany) =>{
      this.company = newCompany
    })
    this.connection.user$.subscribe((User) =>{
      this.user = User;
    });

    if(this.user && this.company){
      if(this.company.configs.receipt != 1){
        if(this.cacheService.getItemLocal('Address_Saved'))
        {
          const address = this.cacheService.getItemLocal('Address_Saved');
          this.verifiAddressSaved(address);
        }
        else{  
  
          if(this.methodReceipt.checked && this.methodReceipt.value != 0)
          {
            this.verifiUnitAddress();
          }
  
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.selectedOption = {checked: false, value: 0}
    this.methodReceipt = {checked: false, value: 0}
    this.addressSelected.emit({ checked: false, value: 0 , address: this.addressEmpty });
  }

  //Quando o endereço é selecionado
  onCheckboxChange(data: { checked: boolean, value: any }) {
    this.selectedOption = { checked: data.checked, value: data.value};
    this.selectedAddress(data.value);
  }

  //Quando o botão de alterar e acionado
  changeButton(){
    this.selectedOption.checked = false;
    this.selectedOption.value = 0;
    this.addressSelected.emit({ checked: false, value: 0 , address: this.addressEmpty });
    this.cacheService.removeItemLocal('Address_Saved')
  }

  //Faz o request verificando a distancia e o preço do delivery e repassa para as respectivas funções
  selectedAddress(idAddress: number)
  {
    let formatedAddress;
    let addressUser: Address;

    this.user.address?.forEach((address) =>{
      if(idAddress.toString() == address.id_address)
      {
        addressUser = address;
        formatedAddress = address.formattedaddress;
      }
    })

    if(formatedAddress)
    {
      this.showLoading = true;

      this.apiService.deliveryPrice(this.company.company.id_company.toString(), this.company.company.address.formattedaddress, formatedAddress, idAddress.toString(), this.orderValue).subscribe((data) =>{
        // console.log(data)

        if(data.response.distance <=  Number(this.company.company.minDistanceFree)){

          this.freeDelivery(addressUser, idAddress.toString(), data);

        }
        else if (data.response.distance > Number(this.company.company.maxDistanceDelivery))
        {
          this.selectedOption.checked = false;

          this.selectedOption.value  = 0;

          this.showLoading = false;


          if(!this.toastActive)
          {
            this.toastActive = true;
            this.toastr.warning('Não atendemos nesta região!', 'Desculpe!', 
            { positionClass: 'toast-top-right',
              timeOut: 30000,
              tapToDismiss: true,
              closeButton: true
            }).onHidden.subscribe(() =>{ this.toastActive = false});
          }

        }
        else{

          this.valueDelivery(addressUser, idAddress.toString(), data);

        }

      },
      (error) =>{
        setTimeout(() => {
          this.showLoading = false;
        }, 1500);
      })
    }
  }

  //Entrega grátis
  freeDelivery(addressUser: Address, id_address: string, data: any){

    //carrega as informações nas variaveis e salva em cache
    addressUser.deliveryPrice = Number(data.response.value)
    addressUser.deliverydistance = data.response.distance;
    data = {
      checked: true,
      value: 1,
      address: addressUser 
    }
    this.cacheService.setItemLocal('Address_Saved', addressUser);

    this.addressSelected.emit(data);
    this.addressOption = addressUser;
    setTimeout(() => {
      this.showLoading = false;
    }, 1500);
  }

  //Carrega o valor da entrega no objeto
  valueDelivery(addressUser: Address, id_address: string, data: any){

    //carrega as informações nas variaveis e salva em cache
    addressUser.deliveryPrice = Number(data.response.value);
    addressUser.deliverydistance = data.response.distance;       
    data = {
      checked: true,
      value: 1,
      address: addressUser 
    }
    this.cacheService.setItemLocal('Address_Saved', addressUser);

    this.addressSelected.emit(data);
    this.addressOption = addressUser;

    setTimeout(() => {
      this.showLoading = false;
    }, 1500);
  }

  //Identifica se o tem Endereço salvo em cache, e faz request com o o que esta salvo
  verifiAddressSaved(address: Address){
    const data = {checked: true, value: address.id_address}
    this.onCheckboxChange(data);
  }

  //Indentifica se o usuario tem apenas um endereço cadastrado, e ja seleciona ele automaticamente
  verifiUnitAddress(){
    if(this.user.address?.length == 1){

      const data = {checked: true, value: this.user.address[0].id_address}
      this.onCheckboxChange(data);
    }
  }

  //Redireciona para view para fazer a edição do endereço
  editAddress(id_Address: string, address: Address){
    this.eventsService.eventTypeReceiptSubject.next(this.methodReceipt.value)
    this.geolocation.id_AddressSubject.next(address);
    this.router.navigate([this.nameCompany, 'address'])

  }

  //Deleta o endereço selecionado
  deleteAddress(){
    this.apiService.deleteAddress(this.user.id_customer, this.id_Address).subscribe(
      (response) =>{
        this.renderer.removeStyle(this.Document.body, 'overflow')
        this.user = response.response
        this.connection.updateUser(response.response)
        this.openModalDelete = false;
        this.toastr.success('Endereço excluido com sucesso!', 'Success!', { positionClass: 'toast-top-right', timeOut: 1500});
        const addressSaved = this.cacheService.getItemLocal('Address_Saved')
        this.cacheService.removeItemLocal('Address_Saved')
      },
      (error) =>{

      })

  }

  //Registra endereço em cache
  registerAddress(){
    // this.eventsService.eventTypeReceipt = this.methodReceipt.value
    this.eventsService.eventTypeReceiptSubject.next(this.methodReceipt.value)
    this.router.navigate([this.nameCompany, 'address']);
  }

  //Abre o modal de deletar endereço
  showModalDelete(id_address: string){
    this.openModalDelete = true
    this.id_Address = id_address
  }
}
