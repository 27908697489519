
<!--Cupom-->
<div *ngIf="!this.cupomselecionado && !this.openView" class="cupom-not-selected">
    <div class="box-coupon" [ngClass]="{'info': this.coupomNotValid || this.stringCoupomEmpty}">
        <div class="box-infos">
            <span style="color: var(--primary-color);" class="infos-title">Cupom</span>
            <span class="infos-content">Utilize seus cupons</span>
        </div>

        <button class="button-add" type="button" id="button-addon2" (click)="viewOpen()">Adicionar</button>
    </div>
</div>

<div class="cupom-selected" *ngIf="this.couponSelected && this.cupomselecionado && !this.openView">
    <div class="cupom-selected-content">
        <div class="group-img">
            <img src="./../../../../assets/icons/cupom.png" />
            <div class="span">
                <span class="span-coupon" *ngIf="this.couponSelected.value != 0">{{ this.couponSelected.value | currency:'BRL' }} OFF</span>
                <span class="span-coupon" *ngIf="this.couponSelected.percent != 0">{{ this.couponSelected.percent }}% OFF</span>
                <span class="span-coupon" *ngIf="this.couponSelected.deliveryFree">FRETE GRÁTIS</span>
                <span>Cupom aplicado</span>
            </div>
        </div>
        <button class="button-add" type="button" id="button-addon2" (click)="this.changeButton()">Alterar</button>
    </div>
</div>



<!--View para selecionar o cupom-->
<div *ngIf="this.openView" style="height: 100vh;" [ngClass]="{'response': this.showTopMenu}">
    <div class="container">
        <div class="navegation-header d-flex align-items-center">
            <div class="box-icon" (click)="goBack()">
              <i class="fa fa-chevron-left" aria-hidden="true"></i>
            </div>
            <div class="box-title">
              <h3 style="margin-bottom: 0px;">Cupons</h3>
            </div>
        </div>
    </div>
    <div class="nav-coupons">
      <div class="nav-item" (click)="changeView(1)">
        <span class="nav-name" [ngClass]="{'active': this.viewSelected == 1}">Disponíveis</span>
        <i class="nav-bar" [ngClass]="{'active': this.viewSelected == 1}"></i>
      </div>
      <div class="nav-item" (click)="changeView(2)">
        <span class="nav-name" [ngClass]="{'active': this.viewSelected == 2}">Indisponíveis</span>
        <i class="nav-bar" [ngClass]="{'active': this.viewSelected == 2}"></i>
      </div>
    </div>
    <div class="input-box-coupon" *ngIf="this.viewSelected == 1">
        <div class="box-input" [ngClass]="{'info': this.coupomNotValid || this.stringCoupomEmpty}">
            <div class="input-infos">
                <input type="text" class="form-control" id="cupom" placeholder="Código do cupom" [ngModel]="this.cupomDigit" (ngModelChange)="this.cupomDigit= $event.toUpperCase(); this.checkChange($event)">
            </div>
            <button class="button-add" type="button" id="button-addon2" (click)="this.aplicationCoupom(this.cupomDigit, 1)">Adicionar</button>
        </div>
        <ng-container *ngIf="this.coupomNotValid">
            <span *ngIf="!this.minValue">{{ this.labelNotApliqued }}</span>
            <span *ngIf="this.minValue">Pedido minimo de {{ this.coupomNotValid.minValue | currency:'BRL' }}.</span>
        </ng-container>
        <span *ngIf="this.stringCoupomEmpty" style="font-size: 13px; color: rgb(177, 177, 177);">Por favor insira um cupom.</span>
    </div>

    <div class="box-coupons" *ngIf="this.viewSelected == 1" [ngClass]="{'align-center': this.notCouponsUserValid}" style="height: calc(100% - 174px);">
      <div class="div-not-cupons" *ngIf="this.notCouponsUserValid">
        <img src="assets/icons/cray.png" />
        <span>Você ainda não possui cupons</span>
      </div>
      <ng-container *ngIf="!this.notCouponsUserValid">
        <ng-container *ngFor="let coupon of this.couponsUserValid; let i = index">
          <div class="coupon-option">
            <div class="content">
              <div class="coupon-icon">
                <img src="./../../../../assets/icons/cupom.png" />
              </div>
              <div class="coupon">
                <div class="flag-fidelity" *ngIf="coupon.couponFidelity == 1">
                  <span>Fidelidade</span>
                </div>
                <ng-container *ngIf="coupon.couponValuePercent != 0">
                  <div class="coupon-title">
                    Desconto {{ coupon.couponValuePercent }}%
                  </div>
                </ng-container>
      
                <ng-container *ngIf="coupon.couponValueCash != '0.00'">
                  <div class="coupon-title">
                    Desconto {{ coupon.couponValueCash | currency: 'BRL' }}
                  </div>
                </ng-container>

                <ng-container *ngIf="coupon.couponType == 3">
                  <div class="coupon-title">
                    FRETE GRÁTIS
                  </div>
                </ng-container>
      
                <div class="coupon-content">
                  <div class="coupon-rules" *ngIf="coupon.couponMinValue != '0.00'">
                    Pedido min {{ coupon.couponMinValue | currency: 'BRL' }}
                  </div>
                  <div class="coupon-rules" *ngIf="coupon.couponMinValue == '0.00'">
                    Sem pedido mínimo
                  </div>
                  <div class="coupon-expires" *ngIf="this.datesFormatted[i] != '1'">
                    Expira em {{ this.datesFormatted[i] }} dias
                  </div>
                  <div class="coupon-expires" *ngIf="this.datesFormatted[i] == '1'">
                    Expira hoje
                  </div>

                  <div class="coupon-rules" *ngIf="!coupon.valid">
                    invalido para seu carrinho
                  </div>
                </div>
              </div>
            </div>
            <div class="coupon-more">
              <checkbox-radius (checkboxChange)="this.changeCheckBox($event)" [checkboxData]="{checked: false, value: coupon.coupon}" [uncheck]="this.alteration"></checkbox-radius>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>


    <div class="box-coupons" *ngIf="this.viewSelected == 2" [ngClass]="{'align-center': this.notCouponsUserInvalid}">
      <div class="div-not-cupons" *ngIf="this.notCouponsUserInvalid">
        <img src="assets/icons/cray.png" />
        <span>Tudo vazio por aqui</span>
      </div>
      <ng-container *ngIf="!this.notCouponsUserInvalid">
        <ng-container *ngFor="let coupon of this.couponsUserInvalid; let i = index">
          <div class="coupon-option invalid">
            <div class="content">
              <div class="coupon-icon">
                <img src="./../../../../assets/icons/cupom.png" />
              </div>
              <div class="coupon">
                <div class="flag-fidelity" *ngIf="coupon.couponFidelity == 1">
                  <span>Fidelidade</span>
                </div>
                <ng-container *ngIf="coupon.couponValuePercent != 0">
                  <div class="coupon-title">
                    Desconto {{ coupon.couponValuePercent }}%
                  </div>
                </ng-container>
      
                <ng-container *ngIf="coupon.couponValueCash != '0.00'">
                  <div class="coupon-title">
                    Desconto {{ coupon.couponValueCash | currency: 'BRL' }}
                  </div>
                </ng-container>

                <ng-container *ngIf="coupon.couponType == 3">
                  <div class="coupon-title">
                    FRETE GRÁTIS
                  </div>
                </ng-container>
      
                <div class="coupon-content">
                  <div class="coupon-rules" *ngIf="coupon.couponMinValue != '0.00'">
                    Pedido min {{ coupon.couponMinValue | currency: 'BRL' }}
                  </div>
                  <div class="coupon-rules" *ngIf="coupon.couponMinValue == '0.00'">
                    Sem pedido mínimo
                  </div>
                  <div class="coupon-expires">
                    Expirado
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>

</div>